body.template-tool-property {

  .content-wrap, .content-body {
    max-width: 1600px;
  }

  .content-body {
    padding: 0 0;

    @media(min-width: 1260px){
      padding: 0;
    }
  }
}

#property-app {
  @apply .shadow-lg;
  @apply .flex;
  @apply .items-stretch;
  @apply .rounded-lg;
}
