.lf-table {
  max-width: $MainContainer;
  padding: 50px 20px !important;
  @extend .force-full-width;

  @include breakpoint($bpw_luke) {
    padding: 50 30px !important;
  }


  .table-source {
    text-align: right;
    font-size: 13px;
    padding-top: 7px;

     > span {
      display: inline-block;
      padding-left: 7px;

      &:before {
        content: '';
        display: inline-block;
        height: 12px;
        width: 12px;
        position: relative;
        top: 3px;
        background-image: url("data:image/svg+xml,%3Csvg width='76' height='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.248 26.743h7.5v-7.5h-7.5v7.5zm3.75 41.25c-16.542 0-30-13.459-30-30 0-16.542 13.458-30 30-30 16.541 0 30 13.458 30 30 0 16.541-13.459 30-30 30zm0-67.5c-20.712 0-37.5 16.788-37.5 37.5 0 20.711 16.788 37.5 37.5 37.5 20.711 0 37.5-16.789 37.5-37.5 0-20.712-16.789-37.5-37.5-37.5zm-3.75 56.25h7.5v-22.5h-7.5v22.5z' fill='%23a9162a' fill-rule='nonzero'/%3E%3C/svg%3E");
        background-size: 12px;
        background-position: 0% 50%;
        background-repeat: no-repeat;
      }

      > span.tooltip {
        border-bottom: 1px solid #000;
        display: inline-block;
        height: 15px;
        position: relative;



        &:hover {
          cursor: help;
          .tooltip-text {
            visibility: visible !important;
          }
        }

        > .tooltip-text {
          visibility: hidden;
          width: 200px;
          background-color: $red;
          color: #fff;
          text-align: center;
          border-radius: 4px;
          padding: 4px 2px 4px;
          position: absolute;
          z-index: 1;
          top: -2px;
          right: calc(100% + 25px);

          &:after {
            content: '';
            position: absolute;
            right: -7px;
            top: 3px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 7px solid $red;
          }

        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;


    @media (max-width: 640px){
      border: 0;
    }

    thead {
      background-color: $red;

      tr {
        th {
          p {
            padding: 15px 5px;
            text-align: left;
            color: white;
            font-size: rem(14);
            text-transform: uppercase;
            font-weight: 700;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: white;
        border: 0;
        border-top: 15px solid transparent;
        border-bottom: 1px solid #c7c8ca;

        @media (min-width: 640px){
          &:nth-child(even){
            background-color: $gray-1;
          }
        }

        td {
          padding: 0;

          @media (max-width: 639px){
            &:nth-child(even){
              background-color: $gray-1;
            }
          }

          p {
            padding: 20px 15px;
            font-size: rem(15);
            font-weight: 700;
          }
        }
      }

      .tablesaw-cell-label {
        background-color: $red;
        font-weight: 700;
        p {
          color: white;
        }
      }

    }
  }
}
