body.template-contact {

  .content-body {
    max-width: 920px;
    margin: 0 auto;
    box-shadow: 0px 5px 20px 0 rgba(34, 47, 101, 0.2);
    padding: 50px 30px !important;
    margin-top: -150px;
    background-color: $white;
    position: relative;
    z-index: 2;
    border-radius: 3px;

    @include breakpoint($bpw_jabba) {
      margin-top: -200px;
    }

    @include breakpoint($bpw_deathstar) {
      margin-top: -300px;
    }

    .subhead {
      padding: 20px 0 50px;
      max-width: 700px;
      margin: 0 auto;
      text-align: center;

      p {
        font-size: rem(18);
        line-height: 1.333;

        @include breakpoint($bpw_luke) {
          font-size: rem(25);
          line-height: 1.4;
        }
      }

    }

  }

  // Contact Info

  .contact-info {
    flex-basis: 100%;
    width: 100%;
    max-width: 915px;
    margin: 0 auto;
    padding: 0 20px;
    box-shadow: 0px 5px 20px 0 rgba(34, 47, 101, 0.2);

    @include breakpoint($bpw_luke) {
      padding: 0 30px;
    }

    @include breakpoint($bpw_jabba) {
      padding: 0;
    }
  }

  .contact-bottom {
      display: flex;
      flex-wrap: wrap;

    .left {
      background-color: $red;
      width: 100%;

      @include breakpoint($bpw_fat_yoda) {
        width: 50%;
      }

      @include breakpoint($bpw_vader) {
        width: 30%;
      }

      > div {
        max-width: 310px;
        margin: 0 auto;
      }

      .bottom-text {
        font-size: rem(15);
        line-height: 1.53;
      }
    }
    .right {
      width: 100%;
      padding: 30px 50px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 400px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to top, rgba(0,0,0,0.85), rgba(0,0,0,0.1));
      }

      @include breakpoint($bpw_fat_yoda) {
        width: 50%;
      }

      @include breakpoint($bpw_vader) {
        width: 70%;
        padding: 80px;
      }

      @include breakpoint($bpw_atat) {
        padding: 100px;
      }

      .map-title {
        font-size: rem(18);
        line-height: 1.333;
        margin-bottom: 12px;
        position: relative;
        z-index: 1;

        @include breakpoint($bpw_jabba) {
          font-size: rem(25);
        }
      }

      a:hover {
        .link-button {
          span.card-wrap {
            &:before {
              width: 100%;
              background-position: calc(100% - 10px) center;
              transition: all 0.5s;
            }
          }
        }
      }

      .link-button {
        span.card-wrap {
          color: #fff;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 40px;
          height: 34px;
          font-size: rem(12);
          border-radius: 100px;
          position: relative;

          &:before {
            content: '';
            height: 34px;
            width: 34px;
            background-color: #ad172b;
            background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            position: absolute;
            left: 0;
            border-radius: 100px;
            z-index: 0;
            transition: all 0.5s;
          }
        }

        .card-text {
          position: relative;
          z-index: 1;
        }
      }

    }
  }

  // Recaptcha

  .g-recaptcha > div {
    margin: 0 auto;
  }

}
