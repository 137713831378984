// List
.lf-list {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: $TextContainer;

  &.no-title {
    margin-top: -30px;
  }

  &.two-columns {

    @include breakpoint($bpw_luke) {
      .lf-items {
        display: flex;
        flex-wrap: wrap;

        .lf-list-item {
          flex-basis: calc(50% - 15px);
          margin-right: 15px;

          &:nth-child(even) {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: rem(14);
    line-height: 1.5;
    hyphens: none;
    font-weight: 900;
    padding-bottom: 10px;

    @include breakpoint($bpw_luke) {
      padding-bottom: 20px;
    }
  }

  .lf-list-item {
    border-top: 1px solid $gray-border;
    border-bottom: 1px solid $gray-border;
    margin-bottom: -1px;
    padding: 17px 0;

    p {
      font-size: rem(15);
      line-height: 1.6;

    }
  }
}
