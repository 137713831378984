.lf-image {
  text-align: center;
  padding: 0 !important;
  margin: 30px auto;
  max-width: $TextContainer;
  width: calc(100% - 20px);

  img, picture {
    width: 100%;
    height: auto;
  }

  &.float {
    width: 100%;

    @include breakpoint($bpw_jabba) {
      width: 50%;
      float: right;
      margin-left: 15px;
    }
  }

  .full-width {
    max-width: $MainContainer;

    @include breakpoint($bpw_atst) {
      margin-left: -100px;
      margin-right: -100px;
    }

    @include breakpoint($bpw_atat) {
      margin-left: -225px;
      margin-right: -225px;
    }

    img, picture {
      max-width: none;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &.full-width-wrapper + .full-width-wrapper {
    padding-top: 0px;
  }

  .caption {
    padding: 13px 10px 15px;
    font-size: rem(13);
    color: $black;
    background-color: $gray-1;
    line-height: 1.4;
    margin-top: -1px;
  }
}
