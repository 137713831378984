@charset 'utf-8';

@tailwind base;
@tailwind components;

/* purgecss start ignore */

@import "~breakpoint-sass/stylesheets/_breakpoint";

@import "abstracts/mixins";
@import "abstracts/mixin-animations";
@import "abstracts/variables";

@import "utility/breadcrumb";
@import "utility/animations";
@import "utility/reset";
@import "utility/ie";

@import "base/base";
@import "base/typography";
@import "base/links";
@import "base/forms";
@import "base/utilities";

@import "menus/burger";
@import "menus/navigation";
@import "menus/top-navigation";

@import "layouts/default";

@import "components/editor";
@import "components/buttons";
@import "components/header";
@import "components/page-cover";
@import "components/footer";
@import "components/content";
@import "components/search";
@import "components/pagination";
@import "components/social";
@import "components/section-nav";
@import "components/staff-card";
@import "components/feature-card";
@import "components/post-share";
@import "components/site-message";

// Longform
@import "components/longform/longform";
@import "components/longform/accordion";
@import "components/longform/blockquote";
@import "components/longform/gallery";
@import "components/longform/image";
@import "components/longform/text";
@import "components/longform/video";
@import "components/longform/downloads";
@import "components/longform/table";
@import "components/longform/stat";
@import "components/longform/links";
@import "components/longform/image-cards";
@import "components/longform/embed";
@import "components/longform/image-pair";
@import "components/longform/list";
@import "components/longform/feature";

// Tools
@import "tools/post";
@import "tools/posts";
@import "tools/staff-board";
@import "tools/agenda";
@import "tools/document-center";
@import "tools/map-room";
@import "tools/incentives";
@import "tools/incentive";
@import "tools/major-employers";
@import "tools/properties";
@import "tools/property-listing";
@import "tools/success-stories";
@import "tools/success-story";
@import "tools/key-sector";


// Pages
@import "pages/404";
@import "pages/home";
@import "pages/contact";
@import "pages/search";
@import "pages/section";
@import "pages/covid";

// Glide
@import "~@glidejs/glide/dist/css/glide.core.min";

// Print
@import "print";

/* purgecss end ignore */

@tailwind utilities;
