.footer-wrap {
  background-color: #000;
  padding: 15px;
  position: relative;
  z-index: 5;

  .footer-get-started {
    background-color: #0e1012;
    padding: 45px 15px;
    margin: -15px -15px 0;

    a {
      position: relative;
      z-index: 0;
    }
  }

  footer {
    max-width: $MainContainer;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: $white;
    padding: 30px 0;

    > div {
      padding: 20px 0;
    }

    .left {
      flex-basis: 100%;

      @include breakpoint($bpw_jabba) {
        flex-basis: 25%;
      }

      @include breakpoint($bpw_fat_atst) {
        flex-basis: 15%;
      }

      .footer-logo {
        padding-bottom: 20px;

        svg {
          max-width: 190px;
          height: auto;
        }
      }

      .address, .phone_fax {
        font-size: rem(12);
        line-height: 1.33;

        a {
          color: white;
        }
      }

      .address {
        padding-bottom: 10px;
      }
    }

    .center {
      flex-wrap: wrap;
      flex-basis: 100%;
      justify-content: flex-start;

      @include breakpoint($bpw_jabba) {
        flex-basis: 50%;
      }

      @include breakpoint($bpw_atat) {
        flex-basis: calc(70% - 80px);
        padding-left: 40px;
        padding-right: 40px;
        justify-content: space-between;
      }

      .nav-group {
        text-align: left;
        flex-basis: calc(50% - 10px);
        @apply .pb-3;

        @include breakpoint($bpw_luke) {
          flex-basis: calc(33.333% - 10px);
        }

        @include breakpoint($bpw_atat) {
          flex-basis: calc(20% - 10px);
        }
        .nav-parent {
          font-size: rem(12);
          line-height: 1.33;
          font-weight: 900;
          text-transform: uppercase;
          color: $white;
          display: block;
          padding-bottom: 8px;
        }
        .nav-children {
          a {
            display: block;
            font-weight: 700;
            font-size: rem(12);
            line-height: 1.33;
          }
        }
      }
    }

    .right {
      flex-basis: 100%;

      @include breakpoint($bpw_jabba) {
        flex-basis: 25%;
      }

      @include breakpoint($bpw_fat_atst) {
        flex-basis: 15%;
      }

      .copyright {
        p {
          font-size: rem(12);
          line-height: 1.33;
          color: $white;
        }
      }
    }

    .social-links {
      padding: 30px 0;
    }
  }
}

.footer-social-block {
  @apply .flex;
  @apply .mr-4;
  @apply .mb-6;

  a {
    @apply .h-6;
    @apply .w-6;
    @apply .text-white;
    @apply .border;
    @apply .border-white;
    @apply .rounded-full;
    @apply .flex;
    @apply .items-center;
    @apply .justify-center;
    @apply .mx-1;
    transition: all 0.2s ease-out;

    &:hover {
      @apply .bg-white;
      @apply .text-black;
    }

  }

  svg {
    @apply .h-8;
    @apply .w-8;
    @apply .p-1;
    @apply .fill-current;
    @apply .mx-auto;
  }
}
