.lf-text {
  max-width: $TextContainer;
  margin: 0 auto;
  font-size: rem(16);
  line-height: 1.71;
  // padding-left: 10px !important;
  // padding-right: 10px !important;

  p.dropcap {
    &::first-letter {
      color: $white;
      background-color: $red;
      float: left;
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      font-size: 38px;
      line-height: 1;
      margin-right: 10px;
      margin-top: 10px;
      line-height: 60px;
      padding: 0 20px;

      @include breakpoint($bpw_luke) {
        font-size: 45px;
      }
    }

    @-moz-document url-prefix() {
      &::first-letter {
        padding: 15px 20px;
      }
    }

  }

  > h3 {
    padding-bottom: 3px;
  }

  p {
    padding: 12px 0;
    line-height: 1.65;
    hyphens: auto;
  }

  h2 {
    font-size: rem(25);
    line-height: 1.25;
    color: $primary;
    font-weight: 700;
    padding-top: rem(20);
    margin-bottom: rem(20);
  }

  p ~ p {
    padding: 12px 0;
  }

  h3 {
    font-size: rem(16);
    font-weight: 900;
    line-height: 1.3;
    color: $primary;
    padding-top: rem(15);
  }

  p ~ h3 {
    padding-top: 0;
  }

  h3 ~ p {
    margin-top: 0;
  }

  h4 {
    font-size: rem(20);
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: -5px;

    @include breakpoint($bpw_luke) {
      font-size: rem(20);
    }
  }

  p ~ h4 {
    padding-top: 0;
  }

  h4 ~ p {
    padding-top: 0;
  }

  a {
    color: $red;

    &:hover {
      color: $red_hover;
    }
  }

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  ul,
  ol {
    margin-bottom: 30px;
    margin-left: 40px;
    margin-top: 15px;

    li {
      @include breakpoint($bpw_luke) {
        line-height: 1.6;
      }
    }
  }

  ul {
    margin-bottom: 20px;

    li {
      list-style-type: none;
      line-height: 1.5;

      &:before {
        content: "\2022";
        color: $secondary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  ol {

    li {
      position: relative;
      list-style-type: none;
      counter-increment: list;
      list-style-position: outside;
      line-height: 1.5;

      &:before {
        color: $secondary;
        content: counter(list) ".";
        left: -34px;
        position: absolute;
        text-align: right;
        width: 26px;
      }
    }
  }

  p ~ p {
    padding: 15px 0px;
  }

}
