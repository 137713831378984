.lf-blockquote {
  max-width: $TextContainer;
  margin: 30px auto;
  hyphens: none;
  text-align: center;
  border-top: 1px solid $gray-border;
  border-bottom: 1px solid $gray-border;
  padding: 40px 10px !important;

  @include breakpoint($bpw_jabba) {
    padding: 65px 10px !important;
  }

  p {
    font-size: rem(18);
    line-height: 1.41;
    padding-bottom: 20px;
    font-weight: 900;

    @include breakpoint($bpw_luke){
      font-size: rem(25);
    }
  }

  cite {
    font-style: normal;
    font-size: rem(15);
    font-weight: 900;
    line-height: 1.2;
    color: $red;
    display: block;
    padding-top: 15px;
    text-transform: uppercase;

    span {
      font-size: rem(12);
      font-weight: 300;
      text-transform: none;
      color: $black;
    }
  }
}
