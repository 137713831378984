.lf-gallery {
  max-width: $ContentContainer;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  ul.glide__slides {

    overflow: inherit;
    padding: 10px 0px;
    background-color: ghostwhite;
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius: $radius-small;

    @include breakpoint($bpw_luke) {
      display: flex;
      justify-content: stretch;
      align-content: stretch;
    }

    li.glide__slide {

      list-style-type: none;
      height: auto;
      border-radius: $radius-small;
      transition: transform .2s ease-out;

      >a {
        text-decoration: none;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        position: relative;
        user-select: auto !important;

        &:hover {
          img {
            transform: scale(1.02);
          }
        }

        img {
          width: 100%;
          height: auto;
          border-radius: $radius-small;
          transition: transform 0.2s ease-out;
        }

      }

    }

  }

  .glide__arrows {
    margin-top: 15px;
    text-align: right;

    .glide__arrow {

      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: ghostwhite;
      border: 0;
      outline: none;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: #eee;
        cursor: pointer;
      }

      &.glide__arrow--left {
        background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2215px%22%20height%3D%2228px%22%20viewBox%3D%220%200%2015%2028%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3C%21--%20Generator%3A%20Sketch%2051.3%20%2857544%29%20-%20http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%20--%3E%0A%20%20%20%20%3Ctitle%3Earrow-left%3C%2Ftitle%3E%0A%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%0A%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%0A%20%20%20%20%3Cg%20id%3D%22arrow-left%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22chevron-thin-left%22%20fill%3D%22%232f4f4f%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M14.625246%2C25.9737065%20C15.0590043%2C26.4138906%2015.0590043%2C27.1211626%2014.625246%2C27.5581237%20C14.1914878%2C27.9950849%2013.4907424%2C27.9967165%2013.0570043%2C27.5581237%20L0.384830791%2C14.7597105%20C-0.0489174101%2C14.3227393%20-0.0489174101%2C13.6155056%200.384830791%2C13.1752771%20L13.0570043%2C0.376855827%20C13.4907424%2C-0.0601153957%2014.1915079%2C-0.0601153957%2014.625246%2C0.376855827%20C15.0590043%2C0.815440576%2015.0590043%2C1.52431597%2014.625246%2C1.96128921%20L3.0681695%2C13.9682976%20L14.625246%2C25.9737065%20Z%22%20id%3D%22Shape%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
        background-position: calc(50% - 1px) 50%;
        background-size: 10px;
        background-repeat: no-repeat;
      }

      &.glide__arrow--right {
        background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2215px%22%20height%3D%2228px%22%20viewBox%3D%220%200%2015%2028%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3C%21--%20Generator%3A%20Sketch%2051.3%20%2857544%29%20-%20http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%20--%3E%0A%20%20%20%20%3Ctitle%3Earrow-right%3C%2Ftitle%3E%0A%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%0A%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%0A%20%20%20%20%3Cg%20id%3D%22arrow-right%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22chevron-thin-left%22%20transform%3D%22translate%287.500000%2C%2014.000000%29%20rotate%28-180.000000%29%20translate%28-7.500000%2C%20-14.000000%29%20%22%20fill%3D%22%232f4f4f%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M14.625246%2C25.9737065%20C15.0590043%2C26.4138906%2015.0590043%2C27.1211626%2014.625246%2C27.5581237%20C14.1914878%2C27.9950849%2013.4907424%2C27.9967165%2013.0570043%2C27.5581237%20L0.384830791%2C14.7597105%20C-0.0489174101%2C14.3227393%20-0.0489174101%2C13.6155056%200.384830791%2C13.1752771%20L13.0570043%2C0.376855827%20C13.4907424%2C-0.0601153957%2014.1915079%2C-0.0601153957%2014.625246%2C0.376855827%20C15.0590043%2C0.815440576%2015.0590043%2C1.52431597%2014.625246%2C1.96128921%20L3.0681695%2C13.9682976%20L14.625246%2C25.9737065%20Z%22%20id%3D%22Shape%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
        background-position: calc(50% + 1px) 50%;
        background-size: 10px;
        background-repeat: no-repeat;
      }
    }
  }

}