// =============================================================================
// Font Sizes Here: Be smart keep base font size at 16px unless you want to make your job hard.
// =============================================================================
$base-font-size: 16px;

// =============================================================================
// Font stack here
// =============================================================================
$primary_font: 'museo-sans', sans-serif;
$secondary_font: 'museo-sans', sans-serif;

// =============================================================================
// Layout
// =============================================================================

$WideContainer: rem(1750);
$MainContainer: rem(1550);
$ContentContainer: rem(1280);
$TextContainer: rem(800);

// =============================================================================
// Color Palette
// =============================================================================

// primary
$white:           #fff;
$black:           #000;

$red:             #ad172b;
$red_hover:       #d0112b;

$blue:            #2dabdd;
$orange:          #ffa63d;
$blue-dark:       #223064;

$yellow:          #fff382;
$green:           #52d78b;

$gray:            #f7f7f7;
$gray-border:     #c7c8ca;


$gray-7:     #1f1f1f;
$gray-6:     #403e3d;
$gray-5:     #545251;
$gray-4:     #70706f;
$gray-3:     #919191;
$gray-2:     #cbd5df;
$gray-1:     #ececec;

// Project Specific
$primary: $gray-7;
$secondary: $gray-6;
$tertiary: $gray-4;

$text: $gray-7;

// States
$success: $green;
$alert: $yellow;
$warning: $red;

// =============================================================================
// Border Radius
// =============================================================================

$radius-small: 4px;
$radius-medium: 6px;
$radius-large: 12px;
$radius-full: 999px;

// =============================================================================
// Media Queries
// =============================================================================

//Breakpoint Width
$bpw_mouse_droid:     em(300);
$bpw_mouse_droid_max: em(299);
$bpw_yoda:            em(460);
$bpw_yoda_max:        em(459);
$bpw_fat_yoda:        em(550);
$bpw_fat_yoda_max:    em(549);
$bpw_ewok:            em(680); // tablet
$bpw_ewok_max:        em(679);
$bpw_luke:            em(760);
$bpw_luke_max:        em(759);
$bpw_vader:           em(860);
$bpw_vader_max:       em(859);
$bpw_jabba:           em(1000); // desktop
$bpw_jabba_max:       em(999);
$bpw_atst:            em(1100);
$bpw_atst_max:        em(1099);
$bpw_fat_atst:        em(1200);
$bpw_atat:            em(1300);
$bpw_atat_max:        em(1299);
$bpw_deathstar:       em(1600);
$bpw_deathstar_max:   em(1599);
$bpw_nexu:            em(1900);
$bpw_dianoga:         em(2400);

//Breakpoint Height landscape
$bph_mouse_droid:     em(352);
$bph_yoda:            em(500);
$bph_fat_yoda:        em(600);
$bph_ewok:            em(860); // tablet
$bph_luke:            em(920);
$bph_vader:           em(660);
$bph_jabba:           em(644); // desktop
$bph_atst:            em(580);
$bph_atat:            em(540);
$bph_deathstar:       em(825);
$bph_nexu:            em(855);
$bph_Dianoga:         em(1200);
