.staff-header-wrapper {
  padding: 160px 20px 70px;
  margin-bottom: 10px;

  @include breakpoint($bpw_jabba) {
    padding: 200px 20px 70px;
  }

  .staff-header {
    max-width: 1160px;
    margin: 0 auto;

    h1, .subhead {
      max-width: $TextContainer;
    }
  }
}

body.template-tool-staff-board {

  h1.title {
    color: #111;
    text-align: center;
    font-size: rem(30);
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($bpw_jabba) {
      font-size: rem(42);
    }
  }

  .subhead {
    margin: 0 auto;
    text-align: center;
  }

  .content-body {
    max-width: $MainContainer;
    width: 100%;
    margin: 0 auto;
    //padding: 0 20px !important;
  }

  .item.get-info {
    border: 1px solid $gray-2;
    border-radius: $radius-small;

    .title {
      color: $secondary;
      font-family: $secondary_font;
      font-size: rem(18);
      padding: 20px 15px 0;
      font-weight: 700;
    }

    .body {
      padding: 15px;

      .button {
        margin-top: 20px;
      }
    }

    .action {
      margin-top: 15px;
      padding: 20px;

      &:hover {
        a {
          color: $secondary;
        }
        svg {
          fill: $secondary;
        }
      }

      a {
        text-decoration: none;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.2s ease-out;

        svg {
          height: 14px;
          width: 14px;
          margin-left: 8px;
          fill: $primary;
          transition: all 0.2s ease-out;
        }
      }
    }
  }

  // Staff + Board Cards

  .staff-cards,
  .board-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      flex-basis: calc(100% - 10px);

      @include breakpoint($bpw_jabba) {
        flex-basis: calc(50% - 10px);
      }

      @include breakpoint($bpw_atst) {
        flex-basis: calc(100% - 10px);
      }

      @include breakpoint($bpw_atat) {
        flex-basis: calc(50% - 10px);
      }


    }
  }

  .staffers {
    padding-top: 15px;
  }

  .staffers,
  .board {
    padding-bottom: 80px;

    .text {

      .full {
        display: none;
      }

      &.read-more {
        .load {
          display: none;
        }
        .full {
          display: block;
        }
      }

      .read-more-toggle {

        &.toggled {
          svg {
            transform: rotate(180deg);
          }
        }


        &.hide {
          display: none;
        }
      }
    }
  }
}
