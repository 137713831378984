.tran-all {
  transition: all 0.2s ease-out;
}

.tran-color {
  transition: color 0.2s ease-out;
}

.tran-bg-color {
  transition: background-color 0.2s ease-out;
}
.tran-transform {
  transition: transform 0.2s ease-out;
}
.tran-opacity {
  transition: opacity 0.2s ease-out;
}

.tran-shadow {
  transition: box-shadow 0.2s ease-out;
}

.top-1 {
  transform: translateY(1px);
}

.-top-1 {
  transform: translateY(-1px);
}
