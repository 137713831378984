.lf-accordion {
  max-width: $TextContainer;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 10px;

  .accordion-label {
    text-transform: uppercase;
    font-size: rem(14);
    line-height: 1.5;
    hyphens: none;
    font-weight: 900;
    padding-bottom: 10px;
    transition: all 0.2s ease-out;

    @include breakpoint($bpw_luke) {
      padding-bottom: 20px;
    }

    &:hover {
      color: $red;
      background-color: $white;
    }
  }

  .lf-accordion-item {
    border-radius: $radius-small;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    #accordion-close {
      display: none;
    }

    // If accordion is open
    &.open {
      box-shadow: 0px 5px 20px 0 rgba(34, 47, 101, 0.2);

      .accordion-label {
        border-top-left-radius: $radius-small;
        border-top-right-radius: $radius-small;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $white;
        color: $red;
      }

      #accordion-open {
        display: none;
      }

      #accordion-close {
        display: block !important;
      }

      .accordion-text {
        display: block !important;
        animation-name: appear;
        animation-duration: 0.2s;
      }
    }

    .accordion-label {
      background-color: $red;
      color: $white;
      font-size: rem(15);
      font-weight: 700;
      padding: 17px 25px 17px 15px;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none;

      @include breakpoint($bpw_luke) {
        padding: 22px 30px 22px 25px;
      }

      span:first-child {
        flex-grow: 1;
      }

      svg {
        width: 22px;
        height: 22px;
        fill: $white;
        transition: all 0.2s ease-out;
      }

      &:hover {
        cursor: pointer;

        svg {
          fill: $secondary;
        }
      }
    }

    .accordion-text {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      background-color: $white;
      padding: 0 15px 15px;
      font-size: rem(18);
      line-height: 1.5;
      position: relative;
      z-index: 1;
      margin: 10px 0;
      @apply .rounded;

      @include breakpoint($bpw_luke) {
        padding: 0 45px 30px;
      }

      img {
        width: 100%;
        height: auto;
        margin: 10px 0;
      }

      p {
        font-size: rem(16);
        padding: 10px 0;
        line-height: 1.65;
      }

      a {
        color: $red;
        text-decoration: underline;
      }

      h2 {
        font-size: rem(16);
        line-height: 1.25;
        font-weight: 700;
        padding-top: 15px;
        padding-bottom: 0;
      }

    }
  }
}