.editor-wrapper {
  .editor {
    a {
      position: relative;
      font-size: 14px;
      margin: 0 2px;
      background-color: #841120;
      border: 1px solid #841120;
      @apply .shadow;
      border-radius: 3px;
      color: white;
      display: inline-block;
      padding: 8px 16px;
      text-decoration: none;
      font-weight: 700;
      transition: all 0.2s ease-out;
      display: inline-flex;
      align-items: center;

      span:first-child {
        display: inline-block;
        margin-right: 3px;
      }

      span:last-child {
        transform: translateY(1px);
      }

      &:hover {
        background-color: #841120;
      }
    }
  }
}