.lf-links {
  padding: 0 !important;

  > div {
    margin-bottom: 15px;
    
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: inline-block;
    color: $gray-4;
    text-decoration: underline;
    font-size: rem(16);
    padding-bottom: 2px;
    border-bottom: 1px solid $gray-4; 
    transition: all 0.2s ease-out;
    text-decoration: none;

    &:hover {
      color: $secondary;
      border-color: $secondary;
    }


    @include breakpoint($bpw_luke) {
      font-size: rem(20);
    }
  }

}