body.template-tool-major-employers,
body.template-tool-major-employers-filter {

  .employers-header-wrapper {
    padding: 100px 20px 40px;

    @media (min-width: 1100px) {
      padding-top: 125px;
    }

    .employers-header {
      max-width: $ContentContainer;
      margin: 0 auto;
    }
  }

  .content-body {
    padding: 0 20px;

    @media(min-width: 1260px) {
      padding: 0;
    }
  }

  h1.page-title {
    padding-top: 100px;
    margin-bottom: 55px;

    @include breakpoint($bpw_jabba) {
      padding-left: 0;
    }
  }

  #employers-app {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 0 30px 30px;

    @include breakpoint($bpw_jabba) {
      padding-bottom: 100px;
    }
  }

  .major-employers-wrapper {
    box-shadow: 0 17px 45px 0px rgba(20, 20, 20, 0.1);
    border-radius: 0;
    max-width: $ContentContainer;
    margin: 0 auto;

    .results-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: stretch;
    }

    .results {
      order: 2;
      flex-basis: 100%;

      @include breakpoint($bpw_vader) {
        order: 1;
        flex-basis: calc(30%);
      }

      @include breakpoint($bpw_jabba) {
        flex-basis: calc(25%);
      }

      .items {
        padding: 20px;
        height: 250px;
        max-height: 600px;
        overflow-y: scroll;
        background:
          linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
          linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
          radial-gradient(at top, rgba(0, 0, 0, 0.25), transparent 70%),
          radial-gradient(at bottom, rgba(0, 0, 0, 0.25), transparent 70%) bottom;
        background-repeat: no-repeat;
        background-size: 100% 10px, 100% 10px;
        background-attachment: local, local, scroll, scroll;

        @include breakpoint($bpw_luke) {
          height: 400px;
        }

        @include breakpoint($bpw_jabba) {
          padding: 20px 5px 20px 20px;
          min-height: 600px;
        }

      }

    }

    #map {
      margin: 0;
      order: 1;
      width: 100%;
      flex-basis: 100%;
      min-height: 400px;

      @include breakpoint($bpw_vader) {
        margin: 0 0 0 0;
        order: 2;
        flex-basis: 70%;
      }

      @include breakpoint($bpw_jabba) {
        flex-basis: 75%;
      }

      .map-title {
        font-weight: 700;
        font-size: rem(16);
        line-height: 1;
        color: $primary;
        padding-top: 3px;
        padding-right: 5px;
      }

      .map-industry {
        color: $gray-4;
      }

      .map-employees {
        color: $gray-4;
      }
    }
  }

}

.mapboxgl-popup-close-button {
  padding: 0 5px;
}