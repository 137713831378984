#main-navigation > ul.menu {
  &.open {
    display: block;
    left: 0;
    top: 81px;
    z-index: 999;
    border-top: 8px solid $red;

    &:before {
      content: ' ';
      width: 0;
      height: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='327' height='164' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ad172b' d='M163.5 0L0 164h327z' fill-rule='evenodd'/%3E%3C/svg%3E");
      background-size: 20px;
      background-repeat: no-repeat;
      height: 8px;
      width: 20px;
      position: absolute;
      top: -16px;
      right: 23px;
    }
  }
}

#main-navigation {
  .clearfix {
    &:after {
      clear: none !important;
    }
  }
}

#main-navigation > ul.menu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 60px;
  background-color: #0e1012;
  text-align: left;
  padding-top: 10px;
  padding-left: 0;


  a {
    color: $text;
    font-size: rem(13);

    &:hover {
      color: $gray-4;
    }
  }

  &.open {
    display: block;
  }

  // Functionality + Icons

  > li {
    > ul.menu {
      display: none;
    }

    &.hover {
      > ul.menu {
        display: block;
      }
    }

    &.menu-item--expanded {
      > a {
        background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.998 48.31c-11.753 0-21.315-9.562-21.315-21.315 0-11.753 9.562-21.316 21.315-21.316 11.753 0 21.316 9.563 21.316 21.316 0 11.753-9.563 21.315-21.316 21.315zm0-47.96C12.283.35.354 12.28.354 26.995s11.929 26.644 26.644 26.644c14.716 0 26.645-11.928 26.645-26.644C53.643 12.279 41.714.35 26.998.35zm2.665 13.322h-5.33V24.33H13.677v5.33h10.658v10.657h5.329V29.659H40.32V24.33H29.663V13.672z' fill='%23AD172B' fill-rule='nonzero'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 50%;
        background-size: 16px;

        &:after {
          display: none;
        }
      }

      &.hover {
        > a {
          background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.998 48.311c-11.753 0-21.315-9.563-21.315-21.316 0-11.753 9.562-21.315 21.315-21.315 11.753 0 21.316 9.562 21.316 21.315 0 11.753-9.563 21.316-21.316 21.316zm0-47.96C12.282.35.354 12.279.354 26.995c0 14.716 11.928 26.645 26.644 26.645 14.716 0 26.645-11.929 26.645-26.645S41.714.351 26.998.351zM13.676 29.66h26.645V24.33H13.676v5.329z' fill='%23AD172B' fill-rule='nonzero'/%3E%3C/svg%3E");
          background-position: calc(100% - 10px) 12px;
        }
      }
    }
  }

  > li.menu-item {
      display: none;

      @include breakpoint($bpw_jabba) {
        display: block;
      }
    }

  > li.menu-mobile-item {
      display: block;

      @include breakpoint($bpw_jabba) {
        display: none;
      }
    }

  > li {
    list-style-type: none;
    margin-right: 5px;
    background-size: 10px;
    border-bottom: 1px solid $gray-7;

    > a {
      display: block;
      text-decoration: none;
      font-weight: 900;
      padding: 13px 15px;
      text-transform: uppercase;
      font-size: rem(16);
      color: $white;
    }

    /* --------------- */
    /* -- 2nd LEVEL -- */
    /* --------------- */

    > ul.menu {
      margin: 10px 0 20px 25px;

      li {
        display: block;
        padding: 7px 0px;

        a {
          @apply .text-gray-300;
          text-decoration: none;
          display: block;
          transition: all .2s ease-out;
          font-size: rem(14);
          font-weight: 700;

          &:hover {
            color: $gray-4;
            text-transform: underline;
          }
        }
      }
    }
  }
}

/////////////////////
/// DOWN < TABLET ///
/////////////////////

@media (max-width: $bpw_jabba) {}

///////////////////
/// TABLET > UP ///
///////////////////

@include breakpoint($bpw_atst) {

  #main-navigation {
    display: block;
    position: relative;


    > ul.menu {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      background-color: transparent;
      min-height: 0;
      padding-top: 0px;
      position: relative;
      z-index: 10;
      width: inherit;
      overflow: visible;
      padding: 0;
      width: 100%;

      > li {
        display: block;
        padding: 0;
        position: relative;
        text-align: left;
        margin-right: 10px;
        margin-left: 10px;
        border-bottom: 0;

        @include breakpoint(1400px) {
          margin-right: 20px;
          margin-left: 20px;
        }

        > a {
          position: relative;
        }

        &.child-active {

        }

        &.menu-item--expanded {
          a {
            background-image: none !important;
          }
        }

        &:hover {
          > a {
            position: relative;

            &:after {
              opacity: 1;
              bottom: -1px;
              z-index: 10;
            }
          }
        }

        &.hover {
          &:after {
            content: ' ';
            width: 0;
            height: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='327' height='164' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ad172b' d='M163.5 0L0 164h327z' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-size: 20px;
            background-repeat: no-repeat;
            height: 8px;
            width: 20px;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
            animation-name: appearUpNavArrow;
            animation-duration: 0.2s;
            animation-timing-function: ease-out;
          }
        }

        > a {
          font-weight: 900;
          letter-spacing: 0.52px;
          padding: 0;
          padding-bottom: 15px;
          margin-bottom: -15px;
          color: $white;
          text-transform: uppercase;
          text-decoration: none;
          font-size: rem(13);
          //transition: color 0.2s ease-out;

          &:hover {
            color: $gray-2;
          }
        }

        // 2nd Level
        > ul.menu {
          margin: 0 0 -20px 20px;
          border-top: 8px solid $red;
          position: absolute;
          top: 25px;
          left: -30px;
          //transform: translateX(-50%);
          z-index: 10;
          background-color: $white;
          border-radius: 3px;
          padding: 20px 0 20px;
          width: 315px;
          margin-left: 0;
          animation-name: appearUpNavItem;
          animation-duration: 0.2s;
          animation-timing-function: ease-out;
          @apply .shadow;



          > li {
            display: block;
            padding: 0;
            border-bottom: 0;

            > a {
              text-decoration: none;
              text-align: left;
              display: block;
              padding: 15px 30px;
              color: $black;
              font-size: rem(15);
              font-weight: 700;
              line-height: 1.33333;

              &:hover {
                background-color: $red;
                color: $white;
              }
            }
          }
        }

      }
    }
  }
}

// MENU INFORMATION

.mobile-info {
  display: block;
  padding: 15px;
  margin-top: 20px;

  @include breakpoint($bpw_atst) {
    display: none;
  }

  a {
    color: $white !important;
    font-size: rem(16) !important;
    font-weight: 900;
    text-transform: uppercase;
  }
}
