.pagination {
  text-align: center;
  grid-column: 1 / span 1;

  @include breakpoint($bpw_luke){
    grid-column: 1 / span 2;
  }
}

ul.pagination {
  li {
    display: inline-block;
    @apply .rounded-full;
    transition: all 0.2s ease-out;

    &.disabled {
      span {
        display: none;
      }
    }

    &.active {
      span {
        @apply .bg-red;
        @apply .text-white;
        @apply .font-bold;
      }
    }

    &:not(.active){
      &:hover {
        @apply .shadow;
      }
    }

    a, span {
      display: inline-block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 200px;
      font-size: rem(16);
      text-decoration: none;
      @apply .border;
      @apply .border-red;
      @apply .bg-white;
      @apply .text-red;

      @include breakpoint($bpw_jabba){
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: rem(22);
      }
    }
  }
}
