.content-body-wrap.id-404 {
  .content-body {
    text-align: center;

    .error-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 400px;
      margin: 125px auto 50px;

      @include breakpoint($bpw_jabba) {
        min-height: 500px;
      }

      .error {

        .sorryyy {
          height: 150px;
          width: 150px;
          margin: 0 auto 30px;
          text-align: center;

          svg {
            max-width: 150px;
            fill: $warning;
          }
        }

        h1 {
          font-size: rem(22);
          font-weight: 700;
          padding: 20px 0 0;

          @include breakpoint($bpw_luke) {
            font-size: rem(34);
            padding: 20px 0;
          }
        }

        p {
          font-size: rem(18);

          a {
            color: $red;
            text-decoration: underline;
          }
        }



      }
    }

  }
}
