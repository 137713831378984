.page-cover-wrapper {
  background: linear-gradient(to bottom, $gray-6 52%, white 52.0000001%);
  margin-top: -25px;
  padding-top: 25px;

  .page-cover {
    height: 300px;
    max-width: $MainContainer;
    margin: 0 auto;
    background-color: $gray-3;
    margin-bottom: 50px;

    @include breakpoint($bpw_luke) {
      height: 450px;
    }
  }
}

