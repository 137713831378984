.longform-components {
  margin: 0px auto 0px;

  @include breakpoint($bpw_jabba){
    margin: -35px auto 50px;
  }

  // All Items
  .lf-item {
    padding: 10px 0px;

    @include breakpoint($bpw_luke){
      padding: 20px 0px;
    }

    &:first-child {
      padding-top: 0px;

      @include breakpoint($bpw_jabba){
        padding-top: 15px;
      }
    }
  }

  .lf-item.float + .lf-item {
    padding-top: 0;
  }

}
