.content-wrap#template-tool-success-stories {

  .content-body {
    padding: 125px 0 50px;

    @include breakpoint($bpw_luke) {
      padding: 180px 0 50px;
    }

    @include breakpoint($bpw_atst) {
      padding: 200px 0 50px;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: rem(35);
      font-weight: 900;
      padding-bottom: 25px;
      max-width: $TextContainer;
      margin: 0 auto;

      @include breakpoint($bpw_luke) {
        font-size: rem(75);
      }
    }

    .subhead {
      text-align: center;
      max-width: $TextContainer;
      margin: 0 auto;

      p {
        font-size: rem(18);
        line-height: 1.333;

        @include breakpoint($bpw_luke) {
          font-size: rem(24);
        }
      }
    }
  }

  .success-filters {
    max-width: $TextContainer;
    margin-right: auto;
    margin-left: auto;
  }

  .success-wrapper {
    max-width: $ContentContainer;
    margin: 0 auto;

    .success-stories {

      .success-story {
        width: 100%;
        transition: all 0.2s ease-out;

        @include breakpoint($bpw_luke) {
          width: calc(50% - 10px);
          margin-right: 20px;

          &:nth-child(even) {
            margin-right: 0;
          }
        }

        @include breakpoint($bpw_jabba) {
          width: calc(33.33333% - 14px);
          margin-right: 20px;

          &:nth-child(even) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        &:hover {
          //transform: translateY(-5px);

          .link-button {
            span.card-wrap {
              color: $white;

              &:before {
                width: 100%;
                background-position: calc(100% - 10px) center;
                transition: all 0.5s;
              }
            }
          }

        }

        .success-story-preview-image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .success-tag {
          font-size: 10px;
          float: right;
          position: relative;
          bottom: 12px;
          margin-right: 2rem;

          span {
            font-size: 10px;
            padding: 8px 14px;
          }
        }

        .preview-text {
          font-size: rem(15);
          line-height: 1.53333;
        }

        .link-button {

          span.card-wrap {
            color: #040707;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 40px;
            height: 34px;
            font-size: rem(12);
            border-radius: 100px;
            position: relative;

            &:before {
              content: '';
              height: 34px;
              width: 34px;
              background-color: #040707;
              background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10px;
              position: absolute;
              left: 0;
              border-radius: 100px;
              transition: all 0.5s;
            }

            .card-text {
              position: relative;
              z-index: 1;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  ul.pagination {
    li {
      margin-right: 5px;

      &.active {
        a {
          background-color: $red;
          color: white;
        }
      }
    }
  }
}
