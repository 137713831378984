body.template-home {

  .header-wrap {
    //position: fixed;
    position: relative;
    top: 0;
    width: 100%;
    transition: top 0.2s ease-out;
    padding-top: 0;
    z-index: 10;

    &.nav-shown {
      padding-top: 0;
      transition: top 0.2s ease-out;

      .header-content {
        border-bottom: 0;
      }

      .header-content-wrapper {
        background-color: black;
      }
    }

    .header-content-wrapper {
      background-color: black;

      @include breakpoint($bpw_atst) {
        background-color: transparent;
      }
    }

    .header-content {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  // ---------
  // HOME HERO

  .hero-gradient-overlay {
    height: 150px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(0, 16, 51, 0.75), rgba(0, 16, 51, 0.1) 75%, rgba(0, 16, 51, 0));
    opacity: 0;

    @media (min-width: 1100px) {
      opacity: 1;
    }

  }

  .home-hero {
    position: relative;
    // transform: translateY(-107px);
    // margin-bottom: -107px;
    text-align: center;
    padding: 100px 0;
    height: 500px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;

    @include breakpoint($bpw_atst) {
      transform: translateY(-100px);
      margin-bottom: -100px;
    }


    @include breakpoint($bpw_luke) {
      padding: 150px 0;
      height: 515px;
    }

    @include breakpoint($bpw_jabba) {
      height: 850px;
      padding: 200px 0;
    }

    @include breakpoint($bpw_atst) {
      padding: 250px 0;
    }

    @include breakpoint($bpw_deathstar) {
      padding: 300px 0;
      height: 960px;
    }

    // @include breakpoint(2000px) {
    //   padding-top: 425px;
    //   height: 1200px;
    // }

    .home-hero-bg {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #111;
      z-index: 1;

      &.hide {
        transition: all 0.5s ease-out;
        opacity: 0;
      }
    }


    .hero-subtitle {
      text-align: center;
      color: $white;
      font-size: 25px;
      line-height: 1.2;
      font-style: italic;
      padding-bottom: 5px;
      text-shadow: 0 0 15px black;
      position: relative;
      z-index: 2;

      span {
        display: block;

        @include breakpoint($bpw_jabba) {
          display: inline;
        }
      }

      @include breakpoint($bpw_luke) {
        font-size: 30px;
      }

      @include breakpoint($bpw_jabba) {
        font-size: 40px;
      }
    }

    .hero-title {
      text-shadow: 0px 10px 35px rgba(0, 0, 0, 0.75);
      position: relative;
      z-index: 2;

      .cleveland {
        font-size: 50px;
        color: $white;
        text-transform: uppercase;
        font-weight: 900;
        display: block;

        @include breakpoint($bpw_luke) {
          font-size: 75px;
        }

        @include breakpoint($bpw_jabba) {
          font-size: 150px;

        }
      }

      .ohio {
        display: block;
        font-size: 30px;
        color: $white;
        text-transform: uppercase;
        font-weight: 900;

        @include breakpoint($bpw_luke) {
          font-size: 50px;
        }

        @include breakpoint($bpw_jabba) {
          font-size: 75px;
        }
      }
    }
  }

  // ----------------
  // HOW CAN WE HELP?

  .how-to-help {
    max-width: 980px;
    margin: 0 auto;
    width: calc(100% - 60px);
    margin-top: 73px;
    position: relative;
    z-index: 3;
    box-shadow: 0px 10px 35px 0 rgba(34, 47, 101, 0.75);

    @include breakpoint($bpw_luke) {
      margin-top: 108px;
    }

    @include breakpoint($bpw_jabba) {
      margin-top: 75px;
    }

    &.open {
      .help-items {
        display: block;
      }

      .help-select {
        border-bottom: 1px solid #c7c8ca;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    .help-select {
      &:hover {
        cursor: pointer;
      }
    }

    .help-label {
      font-size: 15px;

      @include breakpoint($bpw_jabba) {
        font-size: 20px;
      }
    }

    .help-items {

      display: none;

      @include breakpoint($bpw_jabba) {
        padding-top: 30px;
        padding-bottom: 40px;
      }

      a {
        text-align: left;
        display: block;
        padding: 15px 15px;
        font-size: 15px;
        transition: all 0.2s ease-out;

        @include breakpoint($bpw_jabba) {
          padding: 20px 30px;
          font-size: 25px;
        }

        &:hover {
          color: $white;
          background-color: $red;
        }
      }
    }
  }

  // ---------------
  // ABOUT CLEVELAND

  .about-cleveland {
    max-width: 1200px;
    width: calc(100% - 60px);
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    align-items: center;

    @include breakpoint($bpw_luke) {
      padding: 100px 0;
    }

    .left {
      flex-basis: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid #c7c8ca;
      margin: 0 auto;

      svg {
        width: 70px;
        height: auto;
      }

      @include breakpoint($bpw_luke) {
        flex-basis: 20%;
        padding: 25px;
        border-bottom: 0;
        padding-right: 15px;

        @media all and (-ms-high-contrast:none) {
          flex-basis: calc(20% - 35px);
        }
      }

      @include breakpoint($bpw_jabba) {
        flex-basis: 18%;
        padding-right: 25px;

        @media all and (-ms-high-contrast:none) {
          flex-basis: calc(18% - 56px);
        }

        svg {
          width: 115px;
        }
      }


    }

    .right {
      flex-basis: 100%;
      padding: 30px 10px 0;

      p {
        font-size: rem(18);
        line-height: 1.5;
        text-align: center;
      }

      @include breakpoint($bpw_luke) {
        padding: 30px 0;
        padding-right: 20px;
        flex-basis: calc(80% - 35px);
        padding-left: 40px;
        border-left: 1px solid #c7c8ca;

        @media all and (-ms-high-contrast:none) {
          flex-basis: calc(80% - 70px);
        }

        p {
          text-align: left;
        }
      }

      @include breakpoint($bpw_jabba) {
        flex-basis: calc(82% - 35px);
        padding-left: 45px;

        @media all and (-ms-high-contrast:none) {
          flex-basis: calc(82% - 80px);
        }

        p {
          font-size: rem(25);
          line-height: 1.4;
          max-width: 730px;
        }
      }

      @include breakpoint($bpw_atat) {
        padding-left: 65px;
      }

    }
  }

  // -----------
  // STATS BLOCK

  .stats-block {
    margin-bottom: 100px;

    .stats-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: 1fr 1fr;

      @include breakpoint($bpw_luke) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;

        -ms-grid-columns: 1fr 1fr;
        -ms-grid-rows: 1fr 1fr 1fr 1fr;
      }

      @include breakpoint($bpw_jabba) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        -ms-grid-columns: 1fr 1fr 1fr;
        -ms-grid-rows: 1fr 1fr 1fr;

      }

      >div,
      a {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $white;
        flex-basis: 100%;
        padding: 40px 20px;
        position: relative;

        @include breakpoint($bpw_luke) {
          padding: 50px 35px;
        }

        @include breakpoint($bpw_fat_atst) {
          padding: 100px 50px;
        }

        @include breakpoint($bpw_deathstar) {
          padding: 100px;
        }

        &.image-stat {
          min-height: 250px;
          position: relative;
          justify-content: flex-end;
          overflow: hidden;

          &:hover {
            cursor: pointer;

            .stat-bg {
              transform: scale(1.1);
            }

            // Hover button
            .link-button {
              span.card-wrap {
                color: $white;

                &:before {
                  width: 100%;
                  background-position: calc(100% - 10px) center;
                  transition: all 0.5s;
                }
              }
            }

            // Change before
            &:before {
              opacity: 1;
              background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0));
            }
          }

          .stat-bg {
            content: '';
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            transition: all 0.2s ease-out;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0));
            transition: opacity 0.5s ease-out;
            opacity: 0.75;
            z-index: 2;
          }

          @include breakpoint($bpw_luke) {
            min-height: 415px;
          }

          @include breakpoint($bpw_jabba) {
            min-height: 450px;
          }

          @include breakpoint($bpw_fat_atst) {
            min-height: 650px;
          }

          .stat-title {
            font-size: rem(18);
            line-height: 1.333;
            padding-bottom: 15px;
            position: relative;
            z-index: 2;

            @include breakpoint($bpw_jabba) {
              font-size: rem(25);
              line-height: 1.4;
            }
          }
        }

        &.text-stat {
          .stat-title {
            font-size: rem(30);
            font-weight: 900;
            padding-bottom: 5px;

            @include breakpoint($bpw_jabba) {
              font-size: rem(45);
            }

            @include breakpoint($bpw_fat_atst) {
              font-size: rem(75);
            }
          }

          .stat-content {
            font-size: rem(16);
            line-height: 1.25;

            @include breakpoint($bpw_jabba) {
              font-size: rem(20);
              line-height: 1.3;
            }

            @include breakpoint($bpw_atat) {
              font-size: rem(25);
              line-height: 1.4;
            }
          }
        }

        // Inside each item
        >div {
          width: 100%;

        }

        .link-button {

          position: relative;
          z-index: 3;

          span.card-wrap {
            color: $white;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 40px;
            height: 34px;
            font-size: rem(12);
            border-radius: 100px;
            position: relative;

            @media all and (-ms-high-contrast:none) {
              min-height: 100px;
            }

            &:after {
              @media all and (-ms-high-contrast:none) {
                content: '';
                min-height: inherit;
                font-size: 0;
              }
            }


            &:before {
              content: '';
              height: 34px;
              width: 34px;
              background-color: $red;
              background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10px;
              position: absolute;
              left: 0;
              border-radius: 100px;
              transition: all 0.5s;
            }

            .card-text {
              position: relative;
              z-index: 1;
              color: $white;
              font-weight: 600;

              @media all and (-ms-high-contrast:none) {
                top: 15px;
              }
            }
          }
        }
      }

      .item-1 {
        grid-area: 1 / 1 / 1 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;

        @include breakpoint($bpw_luke) {
          grid-area: 1 / 1 / 3 / 2;

          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
          -ms-grid-row: 1;
          -ms-grid-row-span: 2;
        }

        @include breakpoint($bpw_jabba) {
          grid-area: 1 / 1 / 3 / 2;
          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
          -ms-grid-row: 1;
          -ms-grid-row-span: 2;
        }

      }

      .item-2 {
        grid-area: 2 / 1 / 3 / 2;
        background-color: $red;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;

        @include breakpoint($bpw_luke) {
          grid-area: 1 / 2 / 2 / 3;

          -ms-grid-column: 2;
          -ms-grid-column-span: 1;
          -ms-grid-row: 1;
          -ms-grid-row-span: 1;
        }

        @include breakpoint($bpw_jabba) {
          grid-area: 1 / 2 / 2 / 4;
          -ms-grid-column: 2;
          -ms-grid-column-span: 2;
          -ms-grid-row: 1;
          -ms-grid-row-span: 1;
        }
      }

      .item-3 {
        grid-area: 2 / 2 / 3 / 3;
        background-color: $black;
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;

        @include breakpoint($bpw_luke) {
          grid-area: 2 / 2 / 3 / 3;
          -ms-grid-column: 2;
          -ms-grid-column-span: 1;
          -ms-grid-row: 2;
          -ms-grid-row-span: 1;
        }

        @include breakpoint($bpw_jabba) {
          -ms-grid-column: 2;
          -ms-grid-column-span: 1;
          -ms-grid-row: 2;
          -ms-grid-row-span: 1;
        }
      }

      .item-4 {
        grid-area: 3 / 1 / 4 / 2;
        background-color: $black;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;
      }

      .item-5 {
        grid-area: 3 / 2 / 4 / 3;
        background-color: $red;
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;

        @include breakpoint($bpw_luke) {
          grid-area: 4 / 1 / 5 / 2;
          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
          -ms-grid-row: 4;
          -ms-grid-row-span: 1;
        }

        @include breakpoint($bpw_jabba) {
          grid-area: 3 / 2 / 4 / 3;
          -ms-grid-column: 2;
          -ms-grid-column-span: 1;
          -ms-grid-row: 3;
          -ms-grid-row-span: 1;
        }
      }

      .item-6 {
        grid-area: 4 / 1 / 5 / 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
        -ms-grid-row: 4;
        -ms-grid-row-span: 1;

        @include breakpoint($bpw_luke) {
          grid-area: 3 / 2 / 5 / 3;
          -ms-grid-column: 2;
          -ms-grid-column-span: 1;
          -ms-grid-row: 3;
          -ms-grid-row-span: 2;
        }

        @include breakpoint($bpw_jabba) {
          grid-area: 2 / 3 / 4 / 4;
          -ms-grid-column: 3;
          -ms-grid-column-span: 1;
          -ms-grid-row: 2;
          -ms-grid-row-span: 2;
        }
      }
    }
  }

  // ----------
  // HOME CARDS

  .home-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
    max-width: $ContentContainer;
    margin: 50px auto 100px;

    >div {
      width: 100%;
      margin-bottom: 20px;

      @include breakpoint($bpw_jabba) {
        width: calc(33.33333% - 10px);
        margin-bottom: 0;
        justify-content: space-between;
      }
    }

    .home-card {

      a {
        display: block;
        height: 100%;
        position: relative;
        box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.2);
        transition: all 0.2s ease-out;
      }

      &>a:hover {
        box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.3);

        span.card-wrap {
          color: $white;

          &:before {
            width: 100%;
            background-position: calc(100% - 10px) center;
            transition: all 0.5s;
          }
        }
      }

      .home-card-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 215px;
        display: none;

        @include breakpoint($bpw_atst) {
          display: block;
        }
      }

      .card-title {
        font-size: rem(15);
      }

      .card-text {
        font-style: rem(15);
        line-height: 1.5;
      }

      .card-footer {
        background-color: $red;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .link-button {

        @media all and (-ms-high-contrast:none) {
          position: relative;
          bottom: 15px;
        }

        span.card-wrap {
          color: #040707;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 40px;
          height: 34px;
          font-size: rem(12);
          border-radius: 100px;
          position: relative;

          &:before {
            content: '';
            height: 34px;
            width: 34px;
            background-color: #040707;
            background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            position: absolute;
            left: 0;
            border-radius: 100px;
            transition: all 0.5s;
          }

          .card-text {
            position: relative;
            z-index: 1;
            color: $white;
            font-weight: 600;

            @media all and (-ms-high-contrast:none) {
              position: relative;
              top: 15px;
            }
          }
        }
      }
    }
  }

  // SECTOR LISTING
  .sectors-card {
    .sector-listing {
      border-top: 1px solid rgba(255, 255, 255, 0.35);
      transition: background-color 0.2s ease-out;
      color: white;

      &:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
      }

      &:hover {
        background-color: white;
        color: $red;

        .listing-text {
          color: $red;
        }
      }

      .listing-text {
        font-size: rem(15);
        transform: translateY(-5px);
      }
    }
  }

  // FEATURE HERO
  .feature-hero {
    min-height: 375px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @media all and (-ms-high-contrast:none) {
      min-height: 375px;

      &:after {
        content: '';
        min-height: inherit;
        font-size: 0;
      }
    }



    @include breakpoint($bpw_jabba) {
      min-height: 560px;
    }

    @include breakpoint($bpw_atat) {
      min-height: 700px;
    }

    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.45);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }


    .feature-hero-content {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 30px);
      max-width: $ContentContainer;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      >div {
        flex-basis: 100%;
        position: relative;
        z-index: 2;
      }

      @include breakpoint($bpw_luke) {
        >div {
          flex-basis: 50%;
        }
      }

      .left {
        color: $white;
        padding-bottom: 15px;

        @include breakpoint($bpw_luke) {
          padding-bottom: 0 !important;
        }

        .subtitle {
          font-size: rem(18);
          font-style: italic;
          padding-bottom: 10px;
        }

        .title {
          font-weight: 900;
          text-transform: uppercase;
          max-width: 630px;
          font-size: rem(35);

          @include breakpoint($bpw_jabba) {
            font-size: rem(54);
          }

          @include breakpoint($bpw_atat) {
            font-size: rem(65);
          }
        }
      }

      .right {

        @include breakpoint($bpw_luke) {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-bottom: 10px;
        }

        a {
          background-color: $red;
          display: inline-block;
          color: $white;
          padding: 12px 35px;
          border-radius: 100px;
          text-align: center;
          font-weight: 600;
          transition: background-color 0.2s ease-out;

          &:hover {
            background-color: #d0112b;
          }
        }
      }
    }
  }

  // -------------
  // A CITY UNITED

  .a-city-united {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    padding-top: 60px;

    @include breakpoint($bpw_jabba) {
      padding-top: 100px;
    }

    @include breakpoint($bpw_fat_atst) {
      min-height: 870px;
    }

    @include breakpoint(1700px) {
      min-height: 900px;
      padding-top: 130px;
    }

    >div {
      width: calc(100% - 30px);
      margin: 0 auto;
      max-width: $ContentContainer;

      .subtitle {
        font-size: rem(18);
        font-style: italic;
        padding-bottom: 12px;
      }

      .title {
        font-size: rem(35);
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 20px;

        @include breakpoint($bpw_jabba) {
          font-size: rem(54);
        }

        @include breakpoint($bpw_fat_atst) {
          font-size: rem(65);
        }
      }

      .text {
        font-size: rem(18);
        line-height: 1.35;
        max-width: 630px;
        margin-bottom: 30px;

        @include breakpoint($bpw_jabba) {
          font-size: rem(25);
        }
      }

      a {
        display: inline-block;
        text-align: center;
        min-width: 200px;
        font-weight: 600;
        background-color: $red;
        color: $white;
        border-radius: 100px;
        padding: 13px 18px;
        transition: background-color 0.2s ease-out;

        &:hover {
          background-color: #d0112b;
        }
      }
    }
  }

  // -------------------
  // FEATURED PROPERTIES

  .properties-listing {
    transform: translateY(-100px);
    margin-bottom: -100px;
    padding-bottom: 40px;
  }

  .all-properties {
    background-color: $black;
    padding: 20px 40px;
    border-radius: 100px;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: $gray-6;
    }

    .button-text {
      font-size: rem(15);
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  .glide__slides {
    li {
      a {
        transition: all 0.2s ease-out;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        }

        .property-button {
          transition: all 0.2s ease-out;
        }

        &:hover {
          .property-button {
            background-color: #ca1a32;
          }
        }
      }
    }
  }

  .glide__arrows {
    position: absolute;
    top: calc(50% - 25px);
    width: 100%;

    .glide__arrow {
      height: 50px;
      width: 50px;
      background-color: $black;
      border-radius: 100px;
      @apply .shadow;
      line-height: 50px;
      text-align: center;
      outline: none;
      transition: all 0.2s ease-out;


      &:hover {
        @apply .shadow-lg;
        transform: scale(1.1);
      }

      svg {
        margin: 0 auto;
        color: $white;
      }
    }

    .glide__arrow--left {
      position: absolute;
      left: 20px;

      svg {
        position: relative;
        right: 2px;
      }
    }

    .glide__arrow--right {
      position: absolute;
      right: 20px;

      svg {
        position: relative;
        left: 2px;
      }
    }
  }

  // ----------
  // HOME POSTS

  .home-posts {
    padding: 0 0;
    transform: translateY(-40px);
    margin-bottom: -40px;
    position: relative;
    z-index: 5;

    @include breakpoint($bpw_jabba) {
      transform: translateY(-100px);
      margin-bottom: -100px;
    }

    @include breakpoint(1700px) {
      transform: translateY(-100px);
      margin-bottom: -125px;
    }

    >div {
      width: calc(100% - 30px);
      margin: 0 auto;
      max-width: $ContentContainer;
      display: grid;
      display: -ms-grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;

      @media all and (-ms-high-contrast:none) {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 auto;
      }

      @include breakpoint($bpw_luke) {
        grid-template-columns: 1fr 1fr;
      }

      @include breakpoint($bpw_jabba) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      >a {

        @media all and (-ms-high-contrast:none) {
          margin: 10px;
          flex-basis: 100%;

          @include breakpoint($bpw_luke) {
            flex-basis: calc(50% - 100px);
          }

          @include breakpoint($bpw_jabba) {
            flex-basis: calc(33.33333% - 100px);
          }
        }




      }

    }

    a.post-card {
      padding: 30px 30px 100px;
      transition: all 0.2s ease-out;
      box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.2);
      position: relative;
      display: block;
      background-color: $white;

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }

      @include breakpoint($bpw_luke) {

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          display: block !important;
        }

        padding: 40px 40px 100px;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.2);

        .card-wrap {
          color: $white;

          &:before {
            width: 100% !important;
            background-position: calc(100% - 10px) center !important;
            transition: all 0.5s !important;
          }
        }
      }

      .card-content {
        flex-grow: 1;

        .title {
          font-size: rem(18);
          line-height: 1.5;
          font-weight: 700;
        }
      }


      .card-footer {
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 30px;
        background-color: $red;
        margin: 40px -30px -30px;
        padding: 10px 30px;

        .link-button {

          @media all and (-ms-high-contrast:none) {
            position: relative;
            bottom: 15px;
          }

          .card-wrap {
            color: #040707;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 40px;
            height: 34px;
            font-size: rem(12);
            border-radius: 100px;
            position: relative;

            &:before {
              content: '';
              height: 34px;
              width: 34px;
              background-color: #040707;
              background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10px;
              position: absolute;
              left: 0;
              border-radius: 100px;
              transition: all 0.5s;
            }

            .card-text {
              position: relative;
              z-index: 1;
              color: $white;
              font-weight: 600;

              @media all and (-ms-high-contrast:none) {
                position: relative;
                top: 15px;
              }

            }
          }
        }
      }
    }
  }


  // ------
  // FOOTER

  .bottom-image {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 50px, rgba(255, 255, 255, 0));
    }

    @include breakpoint($bpw_luke) {
      min-height: 500px;
    }

    @include breakpoint($bpw_jabba) {
      min-height: 550px;
    }

    @include breakpoint($bpw_fat_atst) {
      min-height: 700px;
    }

    @include breakpoint(1700px) {
      min-height: 880px;
    }
  }
}