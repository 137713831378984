.section-nav {
  margin-bottom: 30px;

  .parent {
    color: $black;
    text-transform: uppercase;
    font-size: rem(15);
    font-weight: 900;
    padding-left: 30px;
    margin-bottom: -5px;
  }

  .siblings {
    margin: 10px 0 20px;

    .menu-item {
      a {
        font-size: rem(15);
        line-height: 1.33;
        text-decoration: none;
        color: $black;
        display: block;
        padding: 10px 0 10px 30px;
        font-weight: 700;
      }

      &.active {
        a {
          color: $red;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.37 70.15'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='Layer 1'%3E%3Cpath d='M35.21 0l-7.87 25.92h15.91L35.21 0zM37.67 56.21l19.28 13.85-6.94-22.34-12.34 8.49z'/%3E%3Cpath fill='%23ce0e2d' d='M0 28.54h73.37L21.68 44.63 0 28.54z'/%3E%3Cpath fill='%23aa182c' d='M12.93 70.15l60.43-41.6-51.7 16.05-8.73 25.55z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-size: 18px 18px;
          background-repeat: no-repeat;
          background-position: 0 calc(50% - 1px);
        }
      }
    }
  }
}
