body.template-tool-document-center {

  .document-header-wrapper {
    padding: 160px 20px 70px;

    @include breakpoint($bpw_atst) {
      padding: 210px 20px 70px;
    }

    .document-header {
      max-width: 840px;
      margin: 0 auto;
    }
  }

  h1.title {
    color: #111;
    text-align: center;
    font-size: rem(30);
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($bpw_jabba) {
      font-size: rem(42);
    }
  }

  .subhead {
    margin: 0 auto;
    text-align: center;
  }


  .content-body {
    max-width: $MainContainer;
    width: 100%;
    margin: 0 auto;
  }
}
