@charset 'utf-8';

@media print {

  // Use this stylesheet for print styles only.
  * {
    background-color: transparent;
  }

  .post-share,
  .header-wrap,
  .back-wrapper,
  .Sidebar,
  .footer-wrap,
  #navigation,
  #header-region,
  #footer,
  .breadcrumb,
  .tabs,
  .feed-icon,
  .links {
    display: none;
  }

  .layout-container {
    width: 100%;
  }

  #content,
  .title {
    margin: 20px 0;
    width: auto;
  }

  a {
    &:link,
    &:visited,
    &:any-link,
    &:hover,
    &:active {
      color: black;
    }
  }

  // CSS2 selector to add visible href after links.
  #content a {
    &:link,
    &:visited,
    &:any-link {
      &::after {
        content: " (" attr(href) ") ";
        font-size: 0.8em;
        font-weight: normal;
      }
    }
  }
}


