.maps {
  margin: 40px auto 30px;

  .map {

    .map-container {
      transition: all 0.2s ease-out;
    }

    .image {
      img {
        transition: all 0.2s ease-out;
      }
    }

    &:hover {
      cursor: pointer;

      .image {
        img {
          z-index: -1;
          transform: scale(1.1);
        }
      }

      .map-icon {
        svg {
          fill: $secondary !important;
          transform: scale(1.2);
        }
      }
    }
  }
}
