.content-body {

  h1 {
    font-size: rem(45);
    line-height: 1;
    font-weight: 900;
    margin-bottom: 15px;
  }

}

.force-full-width {
  margin-left: -20px;
  margin-right: -20px;

  @include breakpoint($bpw_luke) {
    margin-left: -30px;
    margin-right: -30px;
  }

  @include breakpoint($bpw_atst) {
    margin-left: -220px;
    margin-right: -220px;
  }
}
