.content-wrap {
  width: calc(100% - 40px);
  margin: 0 auto;

  @include breakpoint($bpw_luke){
    width: calc(100% - 60px);
  }
}

// No Image
.content-hero.no-image {
  @apply .bg-gray-200;
  @apply .items-end;
  min-height: 0px !important;
  margin-bottom: 0 !important;

  @include breakpoint($bpw_luke) {
    margin-bottom: 30px !important;
  }

  @include breakpoint($bpw_atst) {
    min-height: 0px !important;
  }

  .title-wrapper {
    max-width: $TextContainer;
    margin: 0 auto;
    width: 100%;
    padding: 60px 0;

    @media all and (-ms-high-contrast:none) {
      margin: 0;
    }

    @include breakpoint($bpw_jabba) {
      padding: 100px 0;
    }

    @include breakpoint($bpw_atst) {
      //padding-top: 175px;
    }
  }

  h1.page-title {
    color: #111;
    text-shadow: none;
    line-height: 1.15;
    // text-transform: none;
    text-align: center;
    font-size: rem(30);
    //padding-bottom: 25px;
    font-weight: 900;
    letter-spacing: 0.5px;

    @include breakpoint($bpw_jabba) {
      font-size: rem(42);
    }
  }

  .subhead {
    text-align: center;
    margin: 25px auto 0;
  }
}

// With Image
.content-hero {
  background-color: $red;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 30px;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;

  &:after {
    @media all and (-ms-high-contrast:none) {
      content:'';
      min-height:inherit;
      font-size:0;
    }
  }


  @include breakpoint($bpw_jabba) {
    min-height: 600px;
    margin-bottom: 65px;
    //padding-top: 125px;
  }

  @include breakpoint(1500px) {
    min-height: 725px;
  }

  @include breakpoint(1700px) {
    min-height: 775px;
  }

  @include breakpoint(2000px) {
    min-height: 900px;
  }

  @include breakpoint(2200px) {
    min-height: 1050px;
  }

  .cover-image-bg {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: url('/site/themes/main/images/global/blank.png');
  }

  h1.page-title {
    font-size: 35px;
    color: $white;
    text-transform: uppercase;
    font-weight: 900;
    max-width: 800px;
    text-align: center;
    position: relative;
    z-index: 2;
    text-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    //top: 35px;

    @include breakpoint($bpw_luke) {
      font-size: 50px;
    }

    @include breakpoint($bpw_jabba) {
      font-size: 75px;
    }
  }

}

.content-wrap {
  max-width: $ContentContainer;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;

  // Has Sidebar
  &.sidebar {

    .content-sidebar {
      order: 2;
      flex: 1;
      flex-basis: 100%;
      display: none;

      @include breakpoint($bpw_atst) {
        display: flex;
        flex-wrap: wrap;
        max-width: 190px;
      }

      > .item {

      }

    }

    .content-body {
      order: 1;
      flex-basis: 100%;

      @include breakpoint($bpw_atst) {
        order: 2;
        flex: 1;
        padding: 0 30px;
      }
    }
  }

  .content-body {
    max-width: $MainContainer;
    width: 100%;
    margin: 0 auto;
  }

}
