.lf-embed {
  max-width: $TextContainer;
  margin: 0 auto;
  margin-bottom: 20px;
  height: 100%;
  // padding-left: 10px !important;
  // padding-right: 10px !important;

  &.full-width {
    max-width: none;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    .lf-embed-wrapper {
      position: relative;
      min-height: 650px;
      height: 100%;
      margin: 0 20px;
      margin-bottom: 30px;
      overflow-y: scroll;

      @include breakpoint($bpw_jabba) {
        margin: 0 30px;
      }

      iframe, object, embed {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .lf-embed-wrapper {
    iframe, object, embed {
      min-height: 650px;
    }
  }

}
