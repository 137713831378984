.lf-item.lf-feature {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @include breakpoint($bpw_jabba) {
    height: 560px;
  }

  @include breakpoint($bpw_atst) {
    height: 700px;
  }

  @include breakpoint($bpw_deathstar) {
    height: 800px;
  }

  @include breakpoint(2200px) {
    height: 1050px;
  }

  .feature-content {
    max-width: $ContentContainer;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 30px;

    @include breakpoint($bpw_luke) {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .feature-text {
      color: $white;
      font-weight: 900;
      text-transform: uppercase;
      font-size: rem(35);
      max-width: 500px;
      text-shadow: 0px 2px 2px rgba(0,0,0,0.45);

      @include breakpoint($bpw_jabba) {
        max-width: 600px;
        font-size: rem(55);
      }

      @include breakpoint($bpw_atat) {
        font-size: rem(65);
        line-height: 1;
      }
    }

    .feature-button {
      margin-top: 20px;

      @include breakpoint($bpw_jabba) {
        margin-left: 15px;
      }

      a {
        max-width: 400px;
      }
    }
  }
}
