
.post-cover-wrapper {
  margin-bottom: 50px;

  .post-cover {

    @media all and (-ms-high-contrast:none) {
      max-height: 500px;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 400px;

      @media all and (-ms-high-contrast:none) {
        height: auto;
      }

      @include breakpoint($bpw_atat) {
        height: 500px;

          @media all and (-ms-high-contrast:none) {
            height: auto;
            position: relative;
            bottom: 100px;
          }
      }
    }
  }
}

.content-body-wrap.id-posts {
  margin: 0 auto;
  padding-bottom: 100px;

  .content-body-header-wrap {
    padding-top: 150px;
    padding-left: 30px;
    padding-right: 30px;

    @include breakpoint($bpw_atst) {
      padding-top: 190px;
    }

    .content-body-header {
      max-width: $ContentContainer;
      margin: 0 auto;
    }

    h1.title {
      color: #111;
      text-align: center;
      font-size: rem(30);
      text-transform: uppercase;
      font-weight: 900;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint($bpw_jabba) {
        font-size: rem(42);
      }
    }

    .subhead {
      margin: 0 auto;
      text-align: center;
    }

  }

  .content-body-header {

    .section-title {
      @apply .uppercase;
      @apply .pb-2;
      @apply .font-bold;
      @apply .text-gray-700;
    }

    .subhead {
      @apply .leading-normal;
      @apply .max-w-xl;
    }

  }

  .content-body-column {
    max-width: $ContentContainer;
    margin: 0 auto;
  }

  .post-lists {
    padding: 0 15px;

    @include breakpoint($bpw_atat) {
      padding: 0;
    }

    a {
      flex-basis: 100%;
      border: 1px solid #f4f4f4;
      margin-bottom: 30px;
      transition: all 0.2s ease-out;
      @apply .rounded;
      @apply .shadow;

      @include breakpoint($bpw_vader) {
        flex-basis: calc(50% - 15px);
      }

      &:hover {
        @apply .shadow-lg;
      }

      .post-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
