.lf-video {
  margin: 0 auto;
  text-align: center;
  max-width: $TextContainer;

  @include breakpoint($bpw_jabba) {
    margin: 50px auto;
  }

  &.full-width {
    max-width: $MainContainer;
    @extend .force-full-width;
  }

  .lf-video-embed {

    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  .caption {
    padding: 13px 10px 15px;
    font-size: rem(13);
    color: $text;
    background-color: $gray-7;
    color: $white;
    line-height: 1.2;
    margin-top: -1px;
  }

}
