.post-share {

  @include breakpoint($bpw_luke) {
    position: sticky;
    top: 20px;
  }

  a {
    text-decoration: none;
  }

  .share-buttons {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint($bpw_luke) {
      max-width: 50px;
      padding-right: 15px;
    }

    a {
      @apply .rounded;
      @apply .flex;
      @apply .justify-center;
      @apply .items-center;
      @apply .p-2;
      @apply .text-gray-700;
      height: 40px;
      width: 40px;
      transition: all 0.2s ease-out;

      @media all and (-ms-high-contrast:none) {
        display: block;
      }

      &:hover {
        @apply .bg-gray-200;
      }

      svg {
        @apply .h-8;
        @apply .w-8;
        @apply .fill-current;
        transition: all 0.2s ease-out;
      }
    }
  }
}
