html {
  line-height: 1;
}

body {
  font-family: $primary_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $primary;
}

::selection {
	color: #fff;
	background-color: $secondary;
}
