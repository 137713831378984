body.template-tool-success-story {

  .content-hero {
    .page-title {
      &.long-title {
        @include breakpoint($bpw_jabba) {
          position: relative;
          bottom: 50px;
        }
      }
    }
  }

  .success-story-preview-wrapper {
    max-width: 915px;
    width: calc(100% - 60px);
    margin: 0 auto;
    position: relative;
    bottom: 80px;
    margin-bottom: -50px;

    @include breakpoint($bpw_luke) {
      margin-bottom: -110px;
    }

    @include breakpoint($bpw_jabba) {
      bottom: 200px;
      margin-bottom: -150px;
    }

    @include breakpoint($bpw_atat) {
      bottom: 225px;
      margin-bottom: -75px;
    }
  }

  .story-preview {
    background-color: $white;
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 5px 20px 0 rgba(34, 47, 101, 0.2);

    @include breakpoint($bpw_luke) {
      padding: 0 30px;
    }

    > div {
      flex-basis: 100%;
      padding: 20px 20px;
      border-bottom: 1px solid #c7c8ca;

      &:last-child {
        border-bottom: 0;
      }

      @include breakpoint($bpw_jabba) {
        margin: 15px 0;
        flex-basis: 33.333333%;
        border-right: 1px solid #c7c8ca;
        padding: 5px 20px;
        border-bottom: 0;

        @media all and (-ms-high-contrast:none) {
          flex-basis: calc(33.33333% - 41px);
        }

        &:last-child {
          border-right: 0;
        }
      }

      .preview-label {
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        font-size: rem(12);
        padding-bottom: 10px;
        padding-top: 5px;
      }

      .preview-value {
        text-align: center;
        font-size: rem(20);

        .success-tag {
          position: relative;
          bottom: 5px;
          span {
            font-size: 10px;
            padding: 8px 14px;
          }
        }

      }

    }
  }

  .content-body {
    padding: 0 0 50px;

    @include breakpoint($bpw_luke) {
      padding: 60px 0 50px;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: rem(35);
      font-weight: 900;
      padding-bottom: 25px;
      max-width: $TextContainer;
      margin: 0 auto;

      @include breakpoint($bpw_luke) {
        font-size: rem(75);
      }
    }

    .subhead {
      text-align: center;
      max-width: $TextContainer;
      margin: 0 auto;

      p {
        font-size: rem(18);
        line-height: 1.333;

        @include breakpoint($bpw_luke) {
          font-size: rem(24);
        }
      }
    }
  }

  .bottom-links {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @include breakpoint($bpw_luke) {
      flex-wrap: nowrap;
      margin-left: 0;
      margin-right: 0;
    }

    > a {
      flex-grow: 1;
      width: 100%;
      box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.2);
      padding: 40px 40px;
      margin-bottom: 15px;
      transition: all 0.2s ease-out;

      @include breakpoint($bpw_luke) {
        width: 50%;
        margin-bottom: 0;
        padding: 40px 60px;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }

      @include breakpoint($bpw_jabba) {
        padding: 40px 80px;
      }

      .card-text {
        font-weight: 600;
      }

      &:hover {
        transform: scale(1.025);
        cursor: pointer;

        @include breakpoint($bpw_luke) {
          transform: scale(1.075);
        }

        .link-button {
          span.card-wrap {
            color: $white;

            &:before {
              width: 100%;
              background-position: calc(100% - 10px) center;
              transition: all 0.5s;
            }
          }
        }
      }

      .link-title {
        font-size: rem(25);
        line-height: 1.4;
        margin-bottom: 10px;
      }

      .link-button {

        span.card-wrap {
          color: #040707;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 40px;
          height: 34px;
          font-size: rem(12);
          border-radius: 100px;
          position: relative;

          &:before {
            content: '';
            height: 34px;
            width: 34px;
            background-color: #040707;
            background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px;
            position: absolute;
            left: 0;
            border-radius: 100px;
            transition: all 0.5s;
          }

          .card-text {
            position: relative;
            z-index: 1;
          }

        }
      }
    }
  }
}

