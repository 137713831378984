// ---------
// Variables

.lf-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: $TextContainer;
  margin: 0 auto;
  padding: 0 10px 5px !important;

  >div {
    flex-basis: 100%;
    align-items: flex-start;
  }
}

.button {
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 4px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  padding: 15px 60px;
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 100px;
  text-decoration: none;

  span:last-child {
    display: inherit;
  }

  &.black {
    background-color: $black;
    color: $white;

    svg {
      fill: $white;
    }

    &:hover {
      background-color: #333;
    }
  }

  &.red {
    background-color: $red;
    color: white;

    svg {
      fill: $white;
    }

    &:hover {
      background-color: #d0112b;
    }
  }
}