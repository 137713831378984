#top-navigation {
  display: none;

  @include breakpoint($bpw_atst) {
    display: block;
  }
}

#top-navigation > ul.menu {
  &.open {
    display: block;
    left: 0px;
    top: 90px;
    z-index: 999;
  }
}

#top-navigation {
  .clearfix {
    &:after {
      clear: none !important;
    }
  }
}

#top-navigation > ul.menu {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 60px;
  background-color: $gray-1;
  text-align: left;
  padding-top: 0;
  padding-left: 0;


  a {
    color: $text;
  }

  &.open {
    display: block;
  }

  // Functionality + Icons

  > li {
    > ul.menu {
      display: none;
    }

    &.hover {
      > ul.menu {
        display: block;
      }
    }

    &.menu-item--expanded {
      > a {
        background-image: url("data:image/svg+xml,%3Csvg width='94' height='56' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.003 56L94 8.485 83.838 0 47 38.182 8.892 0 0 8.485z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 8px;

        &:after {
          display: none;
        }
      }

      &.hover {
        > a {
          //background-image: none !important;
        }
      }
    }

  }

  > li {
    list-style-type: none;
    margin-left: 5px;
    background-size: 10px;

    > a {
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      padding: 5px 15px 5px 20px;
    }

    /* --------------- */
    /* -- 2nd LEVEL -- */
    /* --------------- */

    > ul.menu {
      margin: 10px 0 10px 40px;

      li {
        display: block;
        padding: 7px 0;

        a {
          color: $black;
          text-decoration: none;
          display: block;
          transition: all .2s ease-out;
          font-weight: 400;
          line-height: 1.35;

          &:hover {
            color: $gray-4;
            text-transform: underline;
          }
        }
      }
    }
  }
}

/////////////////////
/// DOWN < TABLET ///
/////////////////////

@media (max-width: $bpw_jabba) {}

///////////////////
/// TABLET > UP ///
///////////////////

@include breakpoint($bpw_atst) {

  #top-navigation {
    display: block;
    position: relative;


    > ul.menu {
      display: flex;
      align-items: stretch;
      justify-content: flex-end;
      background-color: transparent;
      min-height: 0;
      padding-top: 0;
      position: relative;
      z-index: 10;
      width: inherit;
      overflow: visible;
      padding: 0;
      width: 100%;

      > li {
        display: block;
        padding: 0;
        position: relative;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: -10px;
        top: 4px;

        &:last-child {
          top: 0;

          > a {
            background-color: $white;
            color: $black;
            border-radius: 100px;
            padding: 6px 14px;

            &:hover {
              background-color: $black;
              color: $white;
            }
          }
        }

        > a {
          position: relative;
        }

        &.child-active {

        }

        &.menu-item--expanded {
          a {
            //background-image: none !important;
          }
        }

        &:hover {
          > a {
            position: relative;
          }
        }

        > a {
          font-weight: 700;
          font-size: rem(11);
          padding: 0;
          margin-left: 15px;
          padding-right: 12px;
          color: $white;
          text-decoration: none;
          transition: color 0.2s ease-out;
        }

        // 2nd Level
        > ul.menu {
          margin: 0 0 -20px 20px;
          position: absolute;
          top: 29px;
          left: calc(50% - 5px);
          transform: translateX(-50%);
          z-index: 10;
          background-color: $white;
          border-radius: 3px;
          padding: 10px 0;
          width: 225px;
          margin-left: 0;
          animation-name: appearUpTopNavItem;
          animation-duration: 0.2s;
          animation-timing-function: ease-out;
          @apply .shadow;


          &:before {
            content: ' ';
            width: 0;
            height: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='327' height='164' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M163.5 0L0 164h327z' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-size: 20px;
            background-repeat: no-repeat;
            height: 8px;
            width: 20px;
            position: absolute;
            top: -8px;
            left: 50%;
          }

          > li {
            display: block;
            padding: 0;

            > a {
              text-decoration: none;
              text-align: left;
              display: block;
              padding: 10px 15px;
              color: $black;
              font-size: rem(13);
              font-weight: 700;
              text-align: center;

              &:hover {
                background-color: $red;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
