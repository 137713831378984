.social-links {
  display: flex;
  align-items: center;
  justify-content: center;

    a {
      text-decoration: none;
      transition: all 0.2s ease-out;
      
      &:hover {
        svg {
          fill: $secondary;
        }
      }

      svg {
        height: 35px;
        width: 35px;
        fill: $primary;
      }

    }
}