select::-ms-expand {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*=".svg"] {
    width: 100%;
  }
}

// IE Fix
.select-icon {
  @media all and (-ms-high-contrast:none) {
    position: relative;
    bottom: 0;
  }
}
