.header-wrap.nav-shown {
  @media(max-width: $bpw_atst){
    .burger-icon {
      top: 24px;
    }
  }
}


.burger-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 25px;
  padding: 10px 0;
  z-index: 5;
  transition: color 0.5s ease-in-out;

  @include breakpoint($bpw_luke) {
    top: 20px;
  }

  @include breakpoint($bpw_atst) {
    display: none;
  }

  .text {
    float: left;
    padding-right: 5px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.4px;
    font-size: rem(12);
    color: $white;
    position: relative;
    top: 5px;
  }

  .lines {
    width: 28px;
    height: 28px;
    position: relative;
    left: 3px;
    top: 0px;
    border-radius: 100px;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 22px;
    background: $white;
    border-radius: 9px;
    opacity: 1;
    left: 6px;
    transform: rotate(0deg);
    transition: .25s ease-in-out;


    &:nth-child(1) {
      top: 8px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 15px;
    }

    &:nth-child(4) {
      top: 22px;
    }
  }

  &.open span {
    &:nth-child(1) {
      top: 9px;
      width: 0%;
      left: 50%;
      opacity: 0;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 9px;
      width: 0%;
      left: 50%;
      opacity: 0;
    }
  }
}
