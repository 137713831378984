.lf-image-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 25px;
  margin-bottom: 25px;

  @include breakpoint($bpw_jabba) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.lf-image-card {
  flex-grow: 1;
  flex-basis: 100%;

  @include breakpoint($bpw_jabba) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &:nth-child(odd){
      > div.card-image {
        flex-basis: 57%;
      }
    }

    &:nth-child(even){
      .card-image {
        order: 2;
      }
      .card-content {
        order: 1;
      }

      > div.card-image {
        flex-basis: 57%;
      }
    }

    > div {
      flex-basis: 50%;
      margin-bottom: 30px;
    }
  }

  @include breakpoint($bpw_atat) {
    > div {
      margin-bottom: 60px;
    }
  }

  .card-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 250px;

    @include breakpoint($bpw_luke) {
      height: 350px;
    }

    @include breakpoint($bpw_jabba) {
      height: 500px;
    }

    @include breakpoint($bpw_atat) {
      height: 750px;
    }
  }

  .card-content {
    padding: 20px 20px 30px;
    text-align: center;

    @include breakpoint($bpw_luke) {
      padding: 0 30px 30px;
    }

    @include breakpoint($bpw_jabba) {
      padding: 0 50px 20px;
      margin-top: -20px;
    }

    .card-star {
      padding: 30px 0;

      svg {
        width: 55px;
        height: 55px;
        text-align: center;
        margin: 0 auto;
      }
    }

    .card-text {
      max-width: 500px;
      margin: 0 auto;

      .button-wrapper {
        padding-top: 20px;

        @include breakpoint($bpw_jabba) {
          padding-top: 40px;
        }
        .button {
          max-width: 300px;
          width: 100%;
        }
      }

      p {
        font-size: rem(18);
        line-height: 1.5;

        @include breakpoint($bpw_atat) {
          font-size: rem(25);
        }
      }
    }
  }
}
