.nav-search-form {
  position: absolute;
  top: 38px;
  right: 0;
  min-width: 300px;
  border-top: 8px solid $red;
  background-color: $white;
  border-radius: 3px;
  display: none;

  &.show {
    display: block;
  }

  @include breakpoint($bpw_luke) {
    min-width: 400px;
  }

  @media (min-width: 1100px) {
    min-width: 500px;
  }

  &:before {
    content: ' ';
    width: 0;
    height: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='327' height='164' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ad172b' d='M163.5 0L0 164h327z' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-size: 20px;
    background-repeat: no-repeat;
    height: 8px;
    width: 20px;
    position: absolute;
    top: -15px;
    right: 25px;
  }

  input {
    font-size: rem(15);
    color: $black;
    padding-right: 45px;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.215 83.115L29.44 64.89c-4.455-5.67-7.29-13.365-7.29-21.465C22.15 24.39 37.54 9 56.575 9 75.61 9 91 24.39 91 43.425 91 62.46 75.61 77.85 56.575 77.85c-8.1 0-15.39-2.835-21.465-7.29L16.885 88.785c-.81.81-2.025 1.215-2.835 1.215s-2.025-.405-2.835-1.215c-1.62-1.62-1.62-4.05 0-5.67zM82.9 43.425c0-14.58-11.745-26.325-26.325-26.325S30.25 28.845 30.25 43.425c0 7.29 2.835 13.77 7.695 18.63 4.86 4.86 11.34 7.695 18.63 7.695 14.58 0 26.325-11.745 26.325-26.325z' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-size: 22px;

    ::placeholder {
      color: $black !important;
    }
  }
}
