// ----------
// Staff Card

.staff-card-wrapper {
  background-color: $gray;
  padding: 50px 25px;

  @include breakpoint($bpw_jabba) {
    padding: 65px 25px 80px;
  }

  .staff-card {
    max-width: 980px;
    margin: 0 auto;
    box-shadow: 0px 5px 20px 0 rgba(34, 47, 101, 0.2);

    .image {
      @include breakpoint($bpw_luke) {
        max-width: 250px;
        flex-basis: 250px;
      }
    }

    .social {
      a {
        border: 1px solid $gray-border;
        display: inline-block;
        border-radius: 100px;
        padding: 2px;
        transition: all 0.2s ease-out;

        &:hover {
          background-color: black;
          border-color: black;
          color: white;
        }
      }
    }

    .name {
      font-size: rem(15);
      text-transform: uppercase;
      font-weight: 900;
    }

    .position {
      font-size: rem(18);
      line-height: 1.333;

      @include breakpoint($bpw_jabba) {
        font-size: rem(25);
        line-height: 1.4;
      }
    }

    .contact-button {
      font-size: rem(14);
      border-radius: 100px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 10px 15px;
      background-color: $red;
      color: $white;
      white-space: nowrap;
      transition: all 0.2s ease-out;
      display: block;
      text-align: center;
      font-weight: 600;

      @include breakpoint($bpw_luke) {
        display: inline-block;
        font-size: rem(10);
      }

      &:hover {
        background-color: #c81a31;
      }
    }
  }
}