.content-wrap#template-section {

  padding-top: 150px;

  @include breakpoint($bpw_luke) {
    padding-top: 200px;
  }

  .content-body {
    padding: 0 0 50px;

    @include breakpoint($bpw_luke) {
      padding: 60px 0 50px;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: rem(35);
      font-weight: 900;
      padding-bottom: 25px;
      max-width: $TextContainer;
      margin: 0 auto;

      @include breakpoint($bpw_luke) {
        font-size: rem(75);
      }
    }

    .subhead {
      text-align: center;
      max-width: $TextContainer;
      margin: 0 auto;

      p {
        font-size: rem(18);
        line-height: 1.333;

        @include breakpoint($bpw_luke) {
          font-size: rem(24);
        }
      }
    }

    .section-links{
      display: flex;
      flex-wrap: wrap;
      max-width: $TextContainer;
      margin: 50px auto;

      @include breakpoint($bpw_luke) {
        margin: 100px auto;
      }

      > a {
        flex-basis: 100%;
        font-size: rem(15);
        font-weight: 700;
        border-top: 1px solid $gray-border;
        border-bottom: 1px solid $gray-border;
        margin-bottom: -1px;
        padding: 15px 15px;
        transition: all 0.2s ease-out;
        color: $black;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include breakpoint($bpw_luke) {
          padding: 25px 15px;
          flex-basis: calc(50% - 15px);
          margin-right: 15px;

          &:nth-child(even) {
            margin-right: 0;
            margin-left: 15px;
          }

        }


        &:hover {
          background-color: $red;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
}
