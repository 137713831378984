* {
  hyphens: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {}

h1 {}

h2 {}

h3 {}

h4 {}

a {
  color: $secondary;

  &:hover {
    color: $gray-7;
  }
}

p {
  font-size: rem(16);
  line-height: 1.5;
  color: $text;
}

blockquote {
  p {}
}

ul {
  margin: 0;

  li {
    list-style-type: none;
    color: $text;
  }
}

ol {
  li {
    color: $text;
  }
}

table {
  td {
    @include breakpoint($bpw_luke) {
      font-size: rem(18);
      line-height: 1.6;
    }
  }
}