.back-wrapper {
  padding-top: 12px;
  background-color: #333;

  .back {
    max-width: $MainContainer;

    a {
      background-color: #151515;

      &:hover {
        background-color: #222;
        color: white;
      }
    }
  }
}

.content-body-wrap.id-post {
  width: 100%;
  margin: 0 auto;

  // @include breakpoint($bpw_luke) {
  //   width: calc(100% - 60px);
  // }

  .content-wrap {
    max-width: $MainContainer;
    margin: 0 auto;
    position: relative;
    margin-top: 75px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }

  .Sidebar {
    order: 1;
    width: 100%;
    background-color: white;
    @apply .border-t;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @include breakpoint($bpw_luke) {
      border: 0;
      width: 45px;
      position: relative;
      right: 15px;
      position: sticky;
      top: 10px;
      padding-right: 10px;
    }
  }

  .Content {
    order: 2;
    max-width: 800px;
    margin: 0 auto;
    flex-basis: calc(100% - 40px);

    @include breakpoint($bpw_luke) {
      flex-basis: calc(100% - 60px);
    }

    .page-title h1 {
      color: $black;
      font-size: rem(32);
      font-weight: 900;
      line-height: 1.05;
      padding-bottom: 15px;
      margin-top: -5px;

      @include breakpoint($bpw_fat_yoda) {
        font-size: rem(48);
        padding-bottom: 20px;
        margin-top: -10px;
      }

      @include breakpoint($bpw_jabba) {
        font-size: rem(65);
        padding-bottom: 20px;
        line-height: 1;
      }
    }

    .post-info {

      .post-type {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 900;
      }

      .post-date {
        display: inline-block;
        color: $gray-4;
      }
    }
  }

}
