.feature-card-wrapper {
  max-width: 1750px;
  margin: 0 auto;
  width: calc(100% - 30px);
  background-color: $gray-1;
  margin-bottom: 100px;

  @include breakpoint($bpw_luke) {
    width: calc(100% - 60px);
  }

  .feature-card {
    display: flex;
    flex-direction: column;

    @include breakpoint($bpw_luke) {
      flex-direction: row;
      align-items: stretch;
    }

    @include breakpoint(1400px) {
      align-items: center;
    }

    >div {
      flex-basis: 50%;

      &.left {
        order: 2;
      }

      &.right {
        order: 1;
        min-height: 300px;
      }

      @include breakpoint($bpw_luke) {
        &.left {
          order: 1;
        }

        &.right {
          order: 2;
          min-height: 550px;
        }
      }

      @include breakpoint($bpw_jabba) {
        &.left {
          flex-basis: 40%;
        }

        &.right {
          flex-basis: 60%;
        }
      }
    }

    .left {
      padding: 30px;

      @include breakpoint($bpw_luke) {
        padding: 30px 50px;
      }

      @include breakpoint($bpw_jabba) {
        padding: 30px 70px;
      }

      .card-title {
        font-size: rem(24);
        line-height: 1.2;
        font-weight: 700;
        padding-bottom: 10px;
        hyphens: none;

        @include breakpoint($bpw_luke) {
          font-size: rem(36);
          padding-bottom: 15px;
        }
      }

      .card-body {
        padding-bottom: 20px;

        @include breakpoint($bpw_luke) {}
      }

    }

    .right {
      background-size: cover;
      background-position: center;
    }
  }

}
