body.template-tool-incentives {

  h1.title {
    color: #111;
    text-align: center;
    font-size: rem(30);
    text-transform: uppercase;

    @include breakpoint($bpw_jabba) {
      font-size: rem(42);
    }
  }

  .subhead {
    margin: 0 auto;
    text-align: center;
  }

  .incentives-filter-wrap {
    box-shadow: none;
    padding: 10px 0;

    .filter-label {
      max-width: $ContentContainer;
      margin: 0 auto;
      padding: 0 25px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .incentives-filter {
      display: flex;
      justify-content: center;
      max-width: $ContentContainer;
      margin: 0 auto;
      padding: 0 25px;
      margin-bottom: 10px;

      a {
        display: inline-block;
        font-weight: 900;
        font-size: rem(13);
        text-transform: uppercase;
        padding: 8px 16px;
        text-decoration: none;
        color: $black;
        position: relative;
        z-index: 2;
        @apply .rounded;
        border: 1px solid #e2e2e2;
        margin-right: 5px;
        background-color: #fff;

        &:not(.active){

        }

        &.active {
          position: relative;
          background-color: #333;
          border-color: #333;
          color: $white;
        }

      }
    }

  }

  .incentives-wrapper {
    max-width: $ContentContainer;
    margin: 0 auto;
    padding: 0 15px;


    .incentive-cards {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 50px;
    }

    .incentive-card {
      transition: transform 0.2s ease-out;
      width: 100%;
      margin-bottom: 30px;

      &:not(.hidden){
        display: flex;
        flex-direction: column;
      }

      @include breakpoint($bpw_luke) {
        width: calc(50% - 15px);

        &:nth-child(even) {
          margin-left: 30px;
        }
      }

      @include breakpoint($bpw_jabba) {
        width: calc(33.33333% - 30px);
        margin-left: 15px !important;
        margin-right: 15px !important;
      }

      .card-background {
        flex-grow: 1;
      }

      .summary p {
        @apply .text-base;
      }

      &:hover {
        transform: translateY(-5px);
      }
    }


  }
}
