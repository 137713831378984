@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearUpFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearUpNavItem {
  0% {
    opacity: 0;
    top: 35px;
  }
  100% {
    opacity: 1;
    top: 25px;
  }
}

@keyframes appearUpTopNavItem {
  0% {
    opacity: 0;
    top: 40px;
  }
  100% {
    opacity: 1;
    top: 29px;
  }
}

@keyframes appearUpNavArrow {
  0% {
    opacity: 0;
    bottom: -22px;
  }
  100% {
    opacity: 1;
    bottom: -12px;
  }
}
