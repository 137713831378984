.lf-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: $MainContainer;
  @extend .force-full-width;
  padding: 30px 20px !important;

  @include breakpoint($bpw_luke) {
    padding: 30px !important;
  }
}


.stat {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 35px 30px;
  margin-bottom: 12px;

  @include breakpoint($bpw_luke) {
    padding: 50px 50px;
  }

  @include breakpoint($bpw_jabba) {
    padding: 60px 55px;
  }

  .stat-feature {
    padding-bottom: 10px;

    @include breakpoint($bpw_luke) {
      padding-bottom: 15px;
    }
  }

  .stat-info {
    line-height: 1.25;
  }

  // Sizes

  &.full {
    flex-basis: 100%;

    .stat-feature {
      font-size: 35px;
      font-weight: 900;

      @include breakpoint($bpw_jabba) {
        font-size: 100px;
      }
    }

    .stat-info {
      font-size: 16px;
      max-width: rem(800);
      margin: 0 auto;

      @media all and (-ms-high-contrast:none) {
        margin: 0;
      }

      @include breakpoint($bpw_jabba) {
        font-size: 20px;
      }
    }

  }

  &.half {
    flex-basis: 100%;

    @include breakpoint($bpw_jabba) {
      flex-basis: calc(50% - 12px);

      @media all and (-ms-high-contrast:none) {
        flex-basis: calc(50% - 110px);
      }
    }

    .stat-feature {
      font-size: 35px;
      font-weight: 900;

      @include breakpoint($bpw_jabba) {
        font-size: 45px;
      }
    }

    .stat-info {
      font-size: 16px;

      @include breakpoint($bpw_jabba) {
        font-size: 20px;
      }
    }

  }

  &.third {
    flex-basis: 100%;

    @include breakpoint($bpw_jabba) {
      flex-basis: calc(33.33333% - 12px);

      @media all and (-ms-high-contrast:none) {
        flex-basis: calc(33.33333% - 110px);
      }

    }

    .stat-feature {
      font-size: 35px;
      font-weight: 900;

      @include breakpoint($bpw_jabba) {
        font-size: 45px;
      }
    }

    .stat-info {
      font-size: 16px;

      @include breakpoint($bpw_jabba) {
        font-size: 20px;
      }
    }

  }

  // Colors

  &.red {
    background-color: $red;
  }

  &.black {
    background-color: $black;
  }

  .stat-feature {
    flex-basis: 100%;
    color: $white;
  }

  .stat-info {
    color: $white;
  }

}
