.lf-image-pair {
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;

  @include breakpoint($bpw_luke) {
    justify-content: space-between;
  }

  .image-pair-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1 0 auto;

    &:not(.has-third) {
      > div {
        flex-basis: 100%;
        margin-bottom: 20px;

        @include breakpoint($bpw_luke) {
          flex-basis: calc(50% - 10px);
          margin-bottom: 0;
        }
      }


    }

    &.has-third {
      > div {
        flex-basis: 100%;
        margin-bottom: 20px;

        @include breakpoint($bpw_luke) {
          flex-basis: calc(50% - 10px);
        }

        @include breakpoint($bpw_jabba) {
          margin-bottom: 0;
          flex-basis: calc(33.333% - 13px);
        }
      }
    }
  }



  img, picture {
    width: 100%;
    height: auto;
  }

  &.lf-image-full-width {
    padding: 50px 0;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    .image-pair-wrap {
      max-width: $ContentContainer;
      margin: 0 auto;
    }
  }
}

.lf-image-pair + .lf-image-pair {
  margin-top: -20px;
}

.lf-image-full-width + .lf-image-full-width {
  margin-top: -80px;
}
