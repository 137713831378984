body.template-tool-incentive {

  .content-wrap {
    max-width: $TextContainer;
    margin: 0 auto;
  }

  .summary-content {
    p {
      @apply .text-gray-700;
    }
  }


  .content-body {
    margin: 100px 0;

    .incentive-body {
      padding: 20px 0;

      h2,
      h3 {
        font-size: rem(18);
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 5px;
        line-height: 1.25;
      }

      p {
        margin-bottom: 20px;
      }

      a {
        color: $gray-4;

        &:hover {
          color: $gray-5;
        }
      }

      b,
      strong {
        font-weight: 700;
      }

      i,
      em {
        font-style: italic;
      }

      ul {
        margin-top: 5px;
        margin-left: 40px;
        margin-bottom: 20px;

        @include breakpoint($bpw_luke) {
          margin-left: 50px;
        }

        li {
          list-style-type: none;
          padding-bottom: 5px;
          font-size: rem(16);
          color: $text;
          line-height: 1.5;

          &:before {
            content: "\2022";
            color: $secondary;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }

      ol {
        margin-left: 40px;
        margin-bottom: 20px;
        font-size: rem(18);

        @include breakpoint($bpw_luke) {
          margin-left: 50px;
        }

        li {
          position: relative;
          list-style-type: none;
          counter-increment: list;
          list-style-position: outside;
          line-height: 1.5;
          padding-bottom: 5px;
          color: $text;
          font-size: rem(16);

          @include breakpoint($bpw_luke) {
            font-size: rem(18);
          }

          &:before {
            color: $primary;
            content: counter(list) ".";
            left: -34px;
            position: absolute;
            text-align: right;
            width: 26px;
          }
        }
      }
    }
  }
}