body.template-tool-key-sector {
  .sector-cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin-top: -30px;

    @include breakpoint($bpw_jabba) {
      margin-top: -65px;
    }

    .sector-card {
      flex-basis: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include breakpoint($bpw_luke) {
        flex-basis: 50%;
      }

      @include breakpoint($bpw_atat) {
        flex-basis: 25%;
      }

      .sector-text-card {
        text-align: center;
        padding: 40px 30px;
        min-height: 325px;

        @include breakpoint($bpw_jabba) {
          padding: 65px 30px;
        }

        @include breakpoint($bpw_atat) {
          padding: 80px 30px;
        }

        .card-title {
          font-size: rem(15);
          text-transform: uppercase;
          font-weight: 900;
          color: $white;
          padding-bottom: 20px;
        }

        .card-text {
          max-width: 315px;
          margin: 0 auto;

          p, ul, li, a {
            font-size: rem(15);
            line-height: 1.5;
            color: $white;
          }
        }

        .card-link {
          margin-top: 20px;

          @include breakpoint($bpw_atat) {
            margin-top: 50px;
          }

          a {
            font-size: rem(14);
            text-align: center;
            display: block;
            padding: 15px 5px;
            background-color: $white;
            border-radius: 100px;
            max-width: 280px;
            margin: 0 auto;
            transition: background-color 0.2s ease-out;

            &:hover {
              @apply .bg-gray-200;
            }
          }
        }

        .card-download {
          margin-top: 20px;

          @include breakpoint($bpw_atat) {
            margin-top: 50px;
          }

          a {
            font-size: rem(14);
            text-align: left;
            display: block;
            padding: 15px 20px;
            background-color: $white;
            border-radius: 100px;
            max-width: 280px;
            margin: 0 auto;
            background-image: url("data:image/svg+xml,%3Csvg width='75' height='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M72.874 35.965c1.574 1.574 1.574 4.119 0 5.676L40.346 74.185c-1.574 1.574-4.118 1.574-5.675 0L2.126 41.641c-1.574-1.574-1.574-4.119 0-5.676l3.717-3.716a4.022 4.022 0 0 1 5.742.067l19.219 20.173V4.375A4.008 4.008 0 0 1 34.82.357h5.358a4.008 4.008 0 0 1 4.017 4.018v48.114l19.22-20.173a3.993 3.993 0 0 1 5.741-.067l3.717 3.716z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: calc(100% - 20px) 50%;
            background-size: 10px;
            transition: background-color 0.2s ease-out;

            &:hover {
              @apply .bg-gray-200;
            }
          }
        }

      }

      .sector-image-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 30px;
        min-height: 325px;
        position: relative;
        overflow: hidden;

        &:hover {
          .image-card-bg {
            transform: scale(1.1);
          }
        }

        .image-card-bg {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          z-index: 1;
          transition: all 0.2s ease-out;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(to top, rgba(0,0,0,0.75), rgba(0,0,0,0));
          z-index: 2;
          transition: all 0.2s ease-out;
        }

        .space {
          flex-grow: 1;
        }

        .card-title {
          font-size: rem(18);
          font-weight: 300;
          color: $white;
          max-width: 300px;
          position: relative;
          z-index: 3;

          @include breakpoint($bpw_luke) {
            font-size: rem(25);
            line-height: 1.4;
          }

        }

        .card-actions {
          position: relative;
          z-index: 3;

          .card-download {

            > a {
              flex-grow: 1;
              width: 100%;
              padding: 40px 40px;
              transition: all 0.2s ease-out;

              @include breakpoint($bpw_luke) {
                width: auto;
                margin-bottom: 0;
                padding: 40px 60px;

                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }

              @include breakpoint($bpw_jabba) {
                padding: 40px 80px;
              }

              &:hover {
                transform: scale(1.025);
                cursor: pointer;

                @include breakpoint($bpw_luke) {
                  transform: scale(1.075);
                }

                .link-button {
                  span.card-wrap {
                    &:before {
                      width: 100%;
                      background-position: calc(100% - 10px) center;
                      transition: all 0.5s;
                    }
                  }
                }
              }

              .link-button {


                span.card-wrap {
                  color: #fff;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 40px;
                  height: 34px;
                  font-size: rem(12);
                  border-radius: 100px;
                  position: relative;

                  &:before {
                    content: '';
                    height: 34px;
                    width: 34px;
                    background-color: #ad172b;
                    background-image: url("data:image/svg+xml,%3Csvg width='75' height='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M72.874 35.965c1.574 1.574 1.574 4.119 0 5.676L40.346 74.185c-1.574 1.574-4.118 1.574-5.675 0L2.126 41.641c-1.574-1.574-1.574-4.119 0-5.676l3.717-3.716a4.022 4.022 0 0 1 5.742.067l19.219 20.173V4.375A4.008 4.008 0 0 1 34.82.357h5.358a4.008 4.008 0 0 1 4.017 4.018v48.114l19.22-20.173a3.993 3.993 0 0 1 5.741-.067l3.717 3.716z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 10px;
                    position: absolute;
                    left: 0;
                    border-radius: 100px;
                    z-index: 0;
                    transition: all 0.5s;

                  }
                }

                .card-text {
                  position: relative;
                  z-index: 1;

                  @media all and (-ms-high-contrast:none) {
                    top: 15px;
                  }
                }

              }
            }

          }

          .card-link {
            > a {
              flex-grow: 1;
              width: 100%;
              padding: 40px 40px;
              transition: all 0.2s ease-out;

              @include breakpoint($bpw_luke) {
                width: auto;
                margin-bottom: 0;
                padding: 40px 60px;

                &:first-child {
                  margin-right: 10px;
                }

                &:last-child {
                  margin-left: 10px;
                }
              }

              @include breakpoint($bpw_jabba) {
                padding: 40px 80px;
              }

              &:hover {
                transform: scale(1.025);
                cursor: pointer;

                @include breakpoint($bpw_luke) {
                  transform: scale(1.075);
                }

                .link-button {
                  .card-wrap {
                    &:before {
                      width: 100%;
                      background-position: calc(100% - 10px) center;
                      transition: all 0.5s;
                    }
                  }
                }
              }

              .link-button {

                span.card-wrap {
                  color: #fff;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 40px;
                  height: 34px;
                  font-size: rem(12);
                  border-radius: 100px;
                  position: relative;

                  &:before {
                    content: '';
                    height: 34px;
                    width: 34px;
                    background-color: #ad172b;
                    background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 10px;
                    position: absolute;
                    left: 0;
                    border-radius: 100px;
                    transition: all 0.5s;
                  }
                }

                .card-text {
                  position: relative;
                  z-index: 1;

                  @media all and (-ms-high-contrast:none) {
                    top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Success Stories Feature

.success-stories-feature {
  position: relative;
  color: white;
  border-bottom: 1px solid #222;
  min-height: 500px;

  @include breakpoint($bpw_luke) {
    min-height: 700px;
  }

  @include breakpoint($bpw_jabba) {
    min-height: 900px;
  }

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(360deg, rgba(14, 16, 18, 1) 0%, rgba(14, 16, 18, 0.65) 50%, rgba(14, 16, 18, 0.15) 100%);
  }

  .feature-container {
    position: relative;
    z-index: 2;
    padding: 150px 20px;
    max-width: $ContentContainer;
    margin: 0 auto;

    @media all and (-ms-high-contrast:none) {
      &:after {
        content:'';
        min-height:inherit;
        font-size:0;
      }
    }
  }

  .feature-description {
    &:before, &:after {
      content: '"';
    }
  }

  .left {
    padding-bottom: 20px;

    @include breakpoint($bpw_luke) {
      padding-bottom: 0;
    }

    .feature-title {
      font-size: rem(30);
      font-weight: 900;
      padding-bottom: 3px;

      @include breakpoint($bpw_luke) {
        font-size: rem(40);
      }
    }
  }

  .link-button {

    &:hover {
      cursor: pointer;
      //box-shadow: 0 5px 20px 0 rgba(34, 47, 101, 0.2);

      .card-wrap {
        color: $white;

        &:before {
          width: 100% !important;
          background-position: calc(100% - 10px) center !important;
          transition: all 0.5s !important;
        }
      }
    }

    .card-wrap {
      color: #040707;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
      height: 34px;
      font-size: rem(12);
      border-radius: 100px;
      position: relative;

      &:before {
        content: '';
        height: 34px;
        width: 34px;
        background-color: rgba(0,0,0,0.25);
        background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.606 18.28v12.21h27.508l-10.7 10.702 7.37 7.37L48.96 24.385 24.784.208l-7.37 7.37 10.7 10.701z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;
        position: absolute;
        left: 0;
        border-radius: 100px;
        transition: all 0.5s;
      }

      .card-text {
        position: relative;
        z-index: 1;
        color: $white;
        font-weight: 600;

        @media all and (-ms-high-contrast:none) {
          top: 15px;
        }
      }
    }
  }

}
