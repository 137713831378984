.lf-downloads {
  max-width: $TextContainer;
  margin: 0 auto;
  margin-bottom: 15px;
  font-size: rem(16);
  line-height: 1.71;
  padding: 5px 10px !important;
  hyphens: none;

  .download-label {
    text-transform: uppercase;
    font-size: rem(14);
    line-height: 1.5;
    hyphens: none;
    font-weight: 900;
    padding-bottom: 10px;

    @include breakpoint($bpw_luke) {
      padding-bottom: 20px;
    }
  }

  .download-item {
    display: block;
    margin-bottom: 15px;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    .download-header {

      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f7f6f6;
      border: 1px solid #c7c8ca;
      padding: 15px 30px 15px 35px;
      border-radius: 40px;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: $white;
        border: 1px solid #efefef;
        @apply .shadow-lg;
      }


      .download-info {
        flex: 1;
        color: $gray-4;
        width: 1px;
        overflow: hidden;

        p {
          font-size: rem(16);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 10px;
        }
      }

      svg {
        width: 13px;
        height: 13px;
        fill: $primary;
        transition: all 0.2s ease-out;
      }

      &:hover {
        svg {
          fill: $secondary;
        }
      }

    }
  }
}
